import axios from 'axios'


// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    const originalRequest = error.config;

    if(error.response.status === 401 && originalRequest.url === `${process.env.REACT_APP_SERVER_URL}/api/user/auth/refresh_token`) {
        console.log('wtf');
        localStorage.clear();
        window.location = `${process.env.REACT_APP_SELF}`
    }

    if(error.response.status === 500 && originalRequest.url === `${process.env.REACT_APP_SERVER_URL}/api/user/auth/refresh_token`) {
        console.log('wtf');
        localStorage.clear();
        window.location = `${process.env.REACT_APP_SELF}`
    }



    if (error.response.status === 401 && !originalRequest._retry) {

        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject })
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return axios(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;
        // this is if the person is logged in with the normal user

        let refreshToken = '';
        let token = '';

        refreshToken = window.localStorage.getItem('immutable_refresh_token');
        token = window.localStorage.getItem('immutable_token');

        // this is for the subclient 
        if (localStorage.getItem('subclient_token')) {
            refreshToken = window.localStorage.getItem('subclient_refresh_token');
            token = window.localStorage.getItem('subclient_token');
        }


        return new Promise(function (resolve, reject) {

            let refresh_payload = {
                refreshToken: refreshToken,
                token: token
            }

            axios.post(`${process.env.REACT_APP_SERVER_URL}/api/user/auth/refresh_token`, refresh_payload)
                .then(({ data }) => {

                    // this is for the subclient 
                    if (localStorage.getItem('subclient_token')) {

                        console.log('subclient refresh ---------------');

                        window.localStorage.setItem('subclient_token', data.access_token);
                        window.localStorage.setItem('subclient_refresh_token', data.refresh_token);

                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
                        originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
                        processQueue(null, data.access_token);
                        resolve(axios(originalRequest));
                    } else {
                        // normal use case

                        window.localStorage.setItem('immutable_token', data.access_token);
                        window.localStorage.setItem('immutable_refresh_token', data.refresh_token);

                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
                        originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
                        processQueue(null, data.access_token);
                        resolve(axios(originalRequest));
                    }
                })
                .catch((err) => {
                    processQueue(err, null);
                    reject(err);
                })
                .then(() => { isRefreshing = false })
        })
    }

    return Promise.reject(error);
});