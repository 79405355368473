import React from "react";
import "../sass/Dashboard.scss";
import MainNavBar from "../TopBar/MainNavBar";
import FatturaManager from "../FatturaContainer/FatturaManager";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import axios from 'axios';

class Dashboard extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      activeUser: ""
    };


    // this.redirectLogin();

    this.searchOnChange = this.searchOnChange.bind(this);
    this.updateSelectedUtente = this.updateSelectedUtente.bind(this);

    this.backToFornitore = this.backToFornitore.bind(this);
    // the immutable_token is saved once, it remains the same for admin, fornitore, user etc ....
    // the token can be mutable, for example when a fornitore or an admin decides to login as another user

    // if the normal token is present and not empty it should be set to it
    // otherwise always use the immutable_token for axios requests
  }


  redirectLogin() {
    if (localStorage.length !== 1) {
      this.props.history.push('/');
    }
  }

  searchOnChange(event) {
    let value = event.target.value;
    setTimeout(() => {
      this.setState({ searchText: value });
    }, 500);
  }

  async updateSelectedUtente(username, password) {
    console.log("logging in with the user" + username + "  " + password);

    await this.context.changeActiveToken(username, password);

    this.setState({ activeUser: username });
  }

  async backToFornitore() {
    console.log("o boi you better");

    // delete the refresh token for the subclient on the db
    let payload = {
      refreshToken: localStorage.getItem('subclient_refresh_token'),
      token: localStorage.getItem('subclient_token')
    }
    await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/user/auth/signout_from_device`, payload)

    // delete the subclients field from the localstorage
    localStorage.removeItem('subclient_token')
    localStorage.removeItem('subclient_refresh_token')
    let token = localStorage.getItem("immutable_token");
    await this.context.firstSignIn(token);
    this.setState({ activeUser: this.context.immutable_username });
  }

  render() {
    return (
      <div>
        <MainNavBar
          searchOnChange={this.searchOnChange}
          updateSelectedUtente={this.updateSelectedUtente}
          backToFornitore={this.backToFornitore}
        />
        {/* This is the right pane */}
        <FatturaManager
          activeUser={this.state.activeUser}
          searchOnChange={this.searchOnChange}
          searchText={this.state.searchText}
        />
      </div>
    );
  }
}

export default Dashboard = withRouter(Dashboard);
