import axios from "axios";
import React, { Component } from "react";
import { Nav } from "react-bootstrap";

import { AuthContext} from '../../../context/auth'

export default class ScaricaZip extends Component {

    static contextType = AuthContext;

    constructor(props) {
    super(props);

    this.state = {};

    this.downloadZip = this.downloadZip.bind(this);
  }

  async downloadZip() {
    let url = `${process.env.REACT_APP_SERVER_URL}/api/fattura/zip/${this.props.source.filename}?direction=${this.props.source.docType}`;

    await axios({
      url: url, //your url
      method: "GET",
      responseType: "blob" // important
    }).then(response => {
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", this.props.source.filename + ".zip"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }

  render() {
    return (
      <div>
        <Nav.Link
          className="navToBtn scaricaZipButtonRightPane"
          onClick={this.downloadZip}
          download
        >
          <p>
            <i className="fas fa-file-archive" /> Scarica ZIP
          </p>
        </Nav.Link>
      </div>
    );
  }
}
