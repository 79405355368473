import React, { Component } from "react";
import { Select, Switch, Input, Button } from "antd";
import axios from "axios";

const { Option } = Select;

export default class EmailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mailing_list: "",
      pecAziendale: "",
      email: "",
      scarti: false,
      decorrenze: false,
      send_gmail: false,
      send_pec: false,
      loading: false
    };
    this.changeEmail = this.changeEmail.bind(this);
    this.saveEmailAndPec = this.saveEmailAndPec.bind(this);
    this.changeInput = this.changeInput.bind(this);
  }

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/user/me`)
      .then(response => {
        console.log(response);

        // we received the values 0 and 1 from the backend
        // we need to cast it to true and false
        // so we use the unary javascript operators

        this.setState({
          mailing_list: response.data.mailing_list,
          scarti: response.data.scarti === 1,
          decorrenze: response.data.decorrenze === 1,
          send_gmail: response.data.send_gmail === 1,
          send_pec: response.data.send_pec === 1,
          email: response.data.email,
          pecAziendale: response.data.pecAziendale
        });
      });
  }

  async changeEmail(mailing_list) {
    let data = {
      mailing_list: mailing_list
    };

    this.setState({ loading: true });
    // send PUT request to
    let response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/mail/update_mailing_list`,
      data
    );

    console.log(response);

    this.setState({ mailing_list });
    this.setState({ loading: false });
  }

  async changeMailSettings(setting, value) {
    let data = {
      setting: setting,
      value: value
    };

    // send PUT request to
    let response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/mail/update_mail_settings`,
      data
    );
    console.log(response);

    this.setState({ [setting]: value });
    console.log(this.state);
  }

  changeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async saveEmailAndPec() {
    let data = {
      email: this.state.email,
      pecAziendale: this.state.pecAziendale
    };
    await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/mail/update_email_and_pec`,
      data
    );

    console.log("saving the email and pec push that to the backend");
  }

  render() {
    return (
      <div style={{ width: "600px" }}>
        <p style={{ color: "black" }}> Indirizzo Email </p>

        <Input
          name="email"
          placeholder="Email normale"
          onChange={this.changeInput}
          value={this.state.email}
        />

        <br />
        <br />

        <p style={{ color: "black" }}> Indirizzo PEC </p>

        <Input
          name="pecAziendale"
          placeholder="Pec aziendale"
          onChange={this.changeInput}
          value={this.state.pecAziendale}
        />

        <br />
        <br />

        <div className="switch_settings">
          <p style={{ fontSize: "19px", color: "black" }}>
            {" "}
            Email fatture scartate{" "}
          </p>
          <Switch
            checked={this.state.scarti}
            defaultChecked
            onChange={checked => this.changeMailSettings("scarti", checked)}
          />
        </div>

        <div className="switch_settings">
          <p style={{ fontSize: "19px", color: "black" }}>
            {" "}
            Email fatture a rischio di decorrenza termini
          </p>
          <Switch
            checked={this.state.decorrenze}
            defaultChecked
            onChange={checked => this.changeMailSettings("decorrenze", checked)}
          />
        </div>

        <div className="switch_settings">
          <p style={{ fontSize: "19px", color: "black" }}>
            {" "}
            Manda le email all'indirizzo email normale{" "}
          </p>
          <Switch
            checked={this.state.send_gmail}
            defaultChecked
            onChange={checked => this.changeMailSettings("send_gmail", checked)}
          />
        </div>

        <div className="switch_settings">
          <p style={{ fontSize: "19px", color: "black" }}>
            {" "}
            Manda le email all'indirizzo pec aziendale{" "}
          </p>
          <Switch
            checked={this.state.send_pec}
            defaultChecked
            onChange={checked => this.changeMailSettings("send_pec", checked)}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontSize: "19px", color: "black" }}>
            {" "}
            Intervallo di email mandate{" "}
          </p>

          <div style={{ textAlign: "center" }}>
            <Select
              className="filtraPerSelect"
              showSearch
              value={this.state.mailing_list}
              placeholder="Select a status you want to filter"
              optionFilterProp="children"
              onChange={this.changeEmail}
              loading={this.state.loading}
            >
              <Option value="none"> Funzione disattivata </Option>
              <Option value="monthly_mail"> Mensile </Option>
              <Option value="weekly_mail"> Settiminale </Option>
              <Option value="daily_mail"> Giornaliero </Option>
              <Option value="instant_mail">Istantanea</Option>
            </Select>
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <Button type="primary" onClick={this.saveEmailAndPec}>
            {" "}
            Salva le modifiche{" "}
          </Button>
        </div>
      </div>
    );
  }
}
