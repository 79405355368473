import { DatePicker } from "antd";
import moment from "moment";
import React, { Component } from "react";

const {  RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD";

export default class TimePicker extends Component {
  constructor(props) {
    super(props);

    var d = new Date();
    d.setMonth(d.getMonth() - 1);

    this.state = {
      startMonth: moment(d).format("YYYY-MM-DD"),
      endMonth: moment(new Date()).format("YYYY-MM-DD") 
    };
  }

  render() {
    return (
        <RangePicker
          defaultValue={[
            moment(this.state.startMonth, dateFormat),
            moment(this.state.endMonth, dateFormat)
          ]}
          format={dateFormat}
          onChange={(moment, dateString) => this.props.timeSelected(dateString)}
        />
    );
  }
}
