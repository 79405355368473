import { Tabs } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
import { AuthContext } from "../../context/auth";
import "../sass/FatturaManager.scss";
import DateFilter from "./DateFilter";
import FatturaList from "./FatturaList";
import getCountPerMonth from "./getCountPerMonth";
import ScaricaZipAll from "./ScaricaZipAll";
import StatusSelect from "./StatusSelect";
import FilterByCliente from "./FilterByCliente";

export default class FatturaManager extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      direction: "in",
      years: [],
      fatture_per_month: [],
      statusFilter: "ricevuta",
      currentDateType: "creationDate",
      currentMonth: (new Date().getMonth() + 1).toString().padStart(2, "0"),
      currentYear: new Date().getFullYear().toString(),
      activeYear: "",

      clienteFilter: "tutti",
      fornitoreFilter: "tutti",
      clienti: [],
      fornitori: []

    };

    this.updateFatturePerMonth = this.updateFatturePerMonth.bind(this);
    this.updateTotalElements = this.updateTotalElements.bind(this);
    this.changeFilterStatus = this.changeFilterStatus.bind(this);

    this.filterCliente = this.filterCliente.bind(this);
    this.filterFornitore = this.filterFornitore.bind(this);

    this.changeMonth = this.changeMonth.bind(this);
    this.changeYear = this.changeYear.bind(this);

    this.setFornitori = this.setFornitori.bind(this);
    this.setClienti = this.setClienti.bind(this);
  }

  componentDidMount() {
    this.get_date_ranges();

    // call the countPerMonth function here
    getCountPerMonth(this.state.direction).then(results =>
      this.setState({ fatture_per_month: results })
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.activeUser !== this.context.username &&
      this.props.activeUser
    ) {
      console.log(this.context.username);
      console.log(this.props.activeUser);
      this.get_date_ranges();

      // first change the year
      getCountPerMonth(this.state.direction).then(results => {
        this.setState({ fatture_per_month: results }, () => {
          console.log("done with the counting");
        });


        console.log(" ------- " + typeof(this.state.activeYear))

        this.changeYear(this.state.activeYear, this.state.statusFilter);
        // set year to last year and month to last month
      });

      // call the countPerMonth function here
    }
  }

  get_date_ranges() {
    return axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/fattura/get_date_ranges`)
      .then(response => {
        this.setState(
          {
//            firstYear: new Date(response.data.first).getFullYear(),
//            lastYear: new Date(response.data.last).getFullYear()
            firstYear: Number(response.data.first),
            lastYear: Number(response.data.last)
          },

          () => {
            this.figureOutLastYear();
            this.getYearTabs();
            console.log("done with year tabs");
          }
        );
      });
  }

  changeFilterStatus(value) {
    if (value === "tutte") {
      console.log("status filter is off");
      this.setState({ statusFilter: "" });
    } else {
      console.log("status filter is on");
      this.setState({ statusFilter: value });
    }
  }


  filterCliente(cliente) {
    console.log("the whole thing must be filtered yo");
    console.log(cliente);
    this.setState({ clienteFilter: cliente });
  }

  filterFornitore(fornitore) {
    console.log("the whole thing must be filtered yo");
    console.log(fornitore);
    this.setState({ fornitoreFilter: fornitore });
  }


  updateTotalElements(numberOfElements) {
    console.log(numberOfElements);
    this.setState({ totalElements: numberOfElements });
  }

  updateFatturePerMonth(index) {
    let newArray = [...this.state.fatture_per_month];
    let item_to_change = { ...newArray[index] };
    item_to_change.not_read_count -= 1;
    newArray[index] = item_to_change;
    console.log(item_to_change);
    this.setState({ fatture_per_month: newArray });
  }

  changeYear(year, statusFilter) {
    this.setState({
      // reset the clienteFilter and the filterFornitore
      clienteFilter: "tutti",
      fornitoreFilter: "tutti",
      currentYear: year,
      statusFilter: statusFilter,
      activeYear: year
    });
  }

  changeMonth(month, statusFilter) {
    console.log(month);
    console.log(statusFilter);
    this.setState({ clienteFilter: "tutti", fornitoreFilter: "tutti", currentMonth: month, statusFilter: statusFilter });
  }

  //as this updates refetch the stuff for this specific date

  changeDirection(activeKey) {
    if (activeKey === "out") {
      this.setState({
        direction: activeKey,
        statusFilter: "tutte",
        currentDateType: "invoices.invoiceDate",
        currentDateTypeZip: "invoices.invoiceDate",
        clienteFilter: "tutti",
        fornitoreFilter: "tutti"
      });
    } else {
      this.setState({
        direction: activeKey,
        statusFilter: "ricevuta",
        currentDateType: "creationDate",
        currentDateTypeZip: "creationDate",
        clienteFilter: "tutti",
        fornitoreFilter: "tutti"
      });
    }
  }


  setClienti(clienti) {
    let uniq = [...new Set(clienti)];
    this.setState({ clienti: uniq })
  }

  setFornitori(fornitori) {
    let uniq = [...new Set(fornitori)];
    this.setState({ fornitori: uniq })
  }


  // this figures out the array of total years for a given user
  getYearTabs() {
    const yearTabs = this.state.lastYear - this.state.firstYear;
    const yearsArray = [];
    for (let i = 0; i <= yearTabs; i++) {
      let year_to_render = parseInt(this.state.firstYear + i);
      //console.log(year_to_render.toString())

      yearsArray.push(year_to_render.toString());
    }

    console.log(yearsArray);
    let last_year = yearsArray[yearsArray.length - 1];
    this.setState({ years: yearsArray, activeYear: last_year });

    // figure out the last year and set it to the active one
    return yearsArray;
  }

  figureOutLastYear() {
    // if the database are desynced or the user received nothing

    // if last Year and current Year are different then use that one as a reference
    // currentYear: this.state.currentYear,

    // Create/pass in this object however you want
    const referenceDate = new Date().getFullYear().toString();

    if(referenceDate !== this.state.lastYear) {
      this.setState({currentYear : this.state.lastYear.toString()})
    } else {
      this.setState({currentYear : referenceDate })
    }
  }


  render() {
    const { TabPane } = Tabs;

    let currentYearAndMonth = {
      currentYear: this.state.currentYear,
      currentMonth: this.state.currentMonth
    };

    return (
      <div>
        {/* This is the left pane */}
        <div className="centerPane">
          <div className="dateBar">
            <DateFilter
              {...currentYearAndMonth}
              direction={this.state.direction}
              activeYear={this.state.activeYear}
              defaultActiveYear={this.state.defaultActiveYear}
              fatture_per_month={this.state.fatture_per_month}
              years={this.state.years}
              changeYear={this.changeYear}
              changeMonth={this.changeMonth}
            />
          </div>

          <div className="listFattureCenter">
            <ListGroup>
              <div>
                <div className="thirdTabDiv">
                  <div className="ricevuteInviateDiv">
                    <Tabs
                      animated={false}
                      className="ricevuteInviateBtn"
                      onChange={this.changeDirection.bind(this)}
                      activeKey={this.state.direction}
                    >
                      <TabPane id="ricevuteBtn" tab="Ricevute" key="in" />
                      <TabPane id="inviateBtn" tab="Inviate" key="out" />
                    </Tabs>
                  </div>

                  <FilterByCliente
                    // this is data
                    clienti={this.state.clienti}
                    fornitori={this.state.fornitori}

                    direction={this.state.direction}

                    // these are flags
                    clienteFilter={this.state.clienteFilter}
                    fornitoreFilter={this.state.fornitoreFilter}

                    // these are methods
                    filterCliente={this.filterCliente}
                    filterFornitore={this.filterFornitore}
                  />

                  {/* direction, username, currentMonth, currentYear */}
                  <ScaricaZipAll
                    direction={this.state.direction}
                    {...currentYearAndMonth}
                  />

                  {/*  direction, statusFilter, changeFilterStatus()  */}
                  <StatusSelect
                    direction={this.state.direction}
                    statusFilter={this.state.statusFilter}
                    changeFilterStatus={this.changeFilterStatus}
                  />
                </div>

                {/*  The DateFilter component sets them */}
                {/* currentMonth, currentYear, currentDateType, currentPage, direction */}
                <FatturaList
                  {...currentYearAndMonth}
                  activeUser={this.props.activeUser}
                  currentDateType={this.state.currentDateType}
                  direction={this.state.direction}
                  fatture_per_month={this.state.fatture_per_month}
                  searchText={this.props.searchText}

                  statusFilter={this.state.statusFilter}
                  updateFatturePerMonth={this.updateFatturePerMonth}
                  updateTotalElements={this.updateTotalElements}

                  setClienti={this.setClienti}
                  setFornitori={this.setFornitori}

                  clienteFilter={this.state.clienteFilter}
                  fornitoreFilter={this.state.fornitoreFilter}
                />
              </div>
              )
            </ListGroup>
          </div>
        </div>
        {/* This is the center pane */}
      </div>
    );
  }
}
