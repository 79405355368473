import { Button, Modal, Select, Checkbox } from "antd";
import axios from "axios";
import React, { Component } from "react";
import TimePicker from "./TimePicker";
import generateReport from "./generateReport";
import moment from 'moment';
import { Buffer } from "buffer";

const { Option } = Select;
export default class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      direction: "in",
      startMonth: "",
      endMonth: "",
      loading: false,
      currentDateType: "invoices.invoiceDate",
      error: "",
      expexcel: false,
      textbutton: "PDF",
      fatture: []
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.timeSelected = this.timeSelected.bind(this);
    this.ExcelSelection = this.ExcelSelection.bind(this);
  }

  componentDidMount() {
    var d = new Date();
    d.setMonth(d.getMonth() - 1);

    this.setState({
      startMonth: moment(d).format("YYYY-MM-DD"),
      endMonth: moment(new Date()).format("YYYY-MM-DD")
    });
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  fetchFatture() {
    this.setState({ loading: true, error: "" });

    let url = `${process.env.REACT_APP_SERVER_URL}/api/fattura/report?direction=${this.state.direction}&startMonth=${this.state.startMonth}&endMonth=${this.state.endMonth}&dateType=${this.state.currentDateType}&excel=${this.state.expexcel}`;

    axios({
      method: "get",
      url: url
    })
      .then(response => {
        if (this.state.expexcel === false) {
          let source_data = response.data.hits.map(i => {
            i._source.id = i._id;
            return i._source;
          });

          this.setState({ loading: false, fatture: source_data }, () =>
            generateReport(
              this.state.direction,
              this.state.startMonth,
              this.state.endMonth,
              this.state.fatture
            )
          );
            } else {
              this.setState({ loading: false });
              var data = response.data.split('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,');
              let your_bytes = Buffer.from(data[1], "base64");
              var blob = new Blob([your_bytes], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = `Report delle fatture ${this.state.direction} dal ${this.state.startMonth} al ${this.state.endMonth}.xlsx`;
              link.click();
         }
      })
      .catch(error => {
        console.log(error.response);

        if (error.response.status === 413) {
          this.setState({
            loading: false,
            error:
              "È possibile esportare i dati relativi ad un periodo massimo di 3 mesi"
          });
        }

        if (error.response.status === 404) {
          this.setState({
            loading: false,
            error: "non ci sono fatture in questa data"
          });
        } else {
          this.setState({
            loading: false,
            error: "errore generico"
          });
        }
      });
  }

  handleOk = e => {
    this.fetchFatture();
  };

  onCurrentDateTypeChange(value) {
    this.setState({ currentDateType: value });
  }

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  onChange(value) {
    console.log(`selected ${value}`);
    this.setState({ direction: value });
  }

  onBlur() {
    console.log("blur");
  }

  onFocus() {
    console.log("focus");
  }

  ExcelSelection(e) {
    console.log(e.target.checked);
    this.setState({ expexcel: e.target.checked });
    if (e.target.checked === true) 
      this.setState({ textbutton: "Excel"});
    else
      this.setState({ textbutton: "PDF"});
  }

  onSearch(val) {
    console.log("search:", val);
  }
  timeSelected(dateString) {
    this.setState({ startMonth: dateString[0], endMonth: dateString[1] });
  }
  render() {
    return (
      <div>
        <Modal
          title="Genera Report"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button id="popUpCancelBtn" key="back" onClick={this.handleCancel}>
              Cancella
            </Button>,
            <Button
              id="popUpScaricaBtn"
              key="submit"
              type="primary"
              loading={this.state.loading}
              onClick={this.handleOk}
            >
              Genera {this.state.textbutton}
            </Button>
          ]}
        >
          <div id="pdfFattureModalDiv">
            <span id="spanFiltraPDF">Filtra fatture per: </span>
            <Select
              id="pdfFattureSelectRI"
              defaultValue="in"
              showSearch
              placeholder="Select a person"
              optionFilterProp="children"
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="in">Ricevute</Option>
              <Option value="out">Inviate</Option>
            </Select>

            <Select
              id="pdfFattureSelectDate"
              showSearch
              placeholder="..."
              defaultValue={this.state.currentDateType}
              optionFilterProp="children"
              onChange={this.onCurrentDateTypeChange.bind(this)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option id="dataRicevute" value="creationDate">
                data ricevute/inviate
              </Option>
              <Option id="dataCreazione" value="invoices.invoiceDate">
                data documento
              </Option>
            </Select>
            <TimePicker timeSelected={this.timeSelected} />
            <Checkbox onChange={this.ExcelSelection}>
               Esporta in Excel
            </Checkbox>
          </div>

          <div>
            <p style={{ color: "red" }}> {this.state.error} </p>
          </div>
        </Modal>

        <Button
          onClick={this.showModal}
          loading={this.state.loading}
          className="navBtn"
          variant="outline-danger"
          id="reportMainBtn"
        >
          <i className="fas fa-file-prescription" />
          Report Fatture
        </Button>
      </div>
    );
  }
}
