import { Button, Modal, Select, Checkbox } from "antd";
import axios from "axios";
import React, { Component } from "react";
import "../sass/FatturaManager.scss";
const { Option } = Select;

export default class ScaricaZipAll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      loading: false,
      currentDateTypeZip: "invoices.invoiceDate",
      not_update_yet: "non_inclusi",
      with_exclusion: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.downloadZip = this.downloadZip.bind(this);
    this.onCurrentDateTypeChangeZip = this.onCurrentDateTypeChangeZip.bind(
      this
    );
    this.ChangeNot_update_yet = this.ChangeNot_update_yet.bind(this);
    this.tickExclusionBox = this.tickExclusionBox.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  onCurrentDateTypeChangeZip(value) {
    this.setState({ currentDateTypeZip: value });
  }

  ChangeNot_update_yet(value) {
    this.setState({ not_update_yet: value });
  }

  tickExclusionBox(e) {
    console.log(e.target.checked);
    this.setState({ with_exclusion: e.target.checked });
  }

  renderDownloadZipButton() {
    const { direction } = this.props;
    const { loading } = this.state;

    if (direction === "in") {
      return (
        <Button
          className="scaricateAllInFattureBtn"
          onClick={() => {
            this.setState({ show: true });
          }}
          type="primary"
          loading={loading}
          disabled={false}
        >
          <i className="fas fa-download" /> Scarica le fatture{" "}
          <b className="extraB"> RICEVUTE </b> di questo mese{" "}
          <i className="fas fa-arrow-down" />
        </Button>
      );
    }
    if (direction === "out") {
      return (
        <Button
          className="scaricateAllOutFattureBtn"
          onClick={() => {
            this.setState({ show: true });
          }}
          type="primary"
          loading={loading}
          disabled={false}
        >
          <i className="fas fa-download" /> Scarica le fatture{" "}
          <b className="extraB"> INVIATE </b> di questo mese{" "}
          <i className="fas fa-arrow-up" />
        </Button>
      );
    }
  }

  downloadZip() {
    const { currentMonth, currentYear, direction } = this.props;
    const { currentDateTypeZip } = this.state;

    this.setState({ loading: true });
    axios({
      url: `${
        process.env.REACT_APP_SERVER_URL
      }/api/fattura/zip/monthly?direction=${direction}&startMonth=${currentYear}-${currentMonth}-01&endMonth=${currentYear}-${currentMonth}-${new Date(
        currentYear,
        currentMonth,
        0
      ).getDate()}&dateType=${currentDateTypeZip}&not_updated_yet=${
        this.state.not_update_yet
      }&with_exclusion=${this.state.with_exclusion}`, //your url
      method: "GET",
      responseType: "blob" // important
    })
      .then(response => {
        this.setState({ loading: false });

        console.log(response);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Fatture ${direction} dal ${currentYear}-${currentMonth}-01 al ${currentYear}-${currentMonth}-${new Date(
            currentYear,
            currentMonth,
            0
          ).getDate()}.zip`
        ); //or any other extension

        console.log(link);

        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <div>
        {this.renderDownloadZipButton()}

        <Modal
          title="Scarica Le Fatture Del Mese"
          visible={this.state.show}
          footer={[
            <Button id="popUpCancelBtn" key="back" onClick={this.handleClose}>
              Cancella
            </Button>,
            <Button
              id="popUpScaricaBtn"
              key="submit"
              type="primary"
              loading={this.state.loading}
              onClick={this.downloadZip}
            >
              Scarica
            </Button>
          ]}
        >
          <div>
            <p id="filtroP">Filtra le fatture da scaricare:</p>
            <div className="selectZipMenu">
              <Select
                showSearch
                id="selectZipByInclude"
                placeholder="..."
                defaultValue={this.state.not_update_yet}
                optionFilterProp="children"
                onChange={this.ChangeNot_update_yet}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option id="dataRicevute" value="inclusi">
                  Includere fatture in stato di attesa S.D.I
                </Option>
                <Option id="dataCreazione" value="non_inclusi">
                  Non includere fatture in stato di attesa S.D.I
                </Option>
              </Select>

              <Select
                showSearch
                id="selectZipByDate"
                placeholder="..."
                defaultValue={this.state.currentDateTypeZip}
                optionFilterProp="children"
                onChange={this.onCurrentDateTypeChangeZip}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option id="dataRicevute" value="invoices.invoiceDate">
                  Filtra le fatture per data ricevute/inviate
                </Option>
                <Option id="dataCreazione" value="creationDate">
                  Filtra le fatture per data documento
                </Option>
              </Select>
              <div id="checkbox">

                {this.props.direction === "in" && (
                  <Checkbox onChange={this.tickExclusionBox}>
                    Date sovrapposte
                  </Checkbox>
                )}

              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
