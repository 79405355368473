import { Button, Checkbox, Icon, Progress } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';

export default class InvioStep extends Component {


    constructor(props) {
        super(props)

        this.state = {
            fileList: [],
            loading: false,
            activeFatturaState: '',
            activeFattura: '',
            bills_in_process: [],
            loadingAruba: false,
            currentErrorProgress: 0,
            currentSuccessProgress: 0,

            errorMessage: '',

            currentErrorCount: 0,
            currentSuccessCount: 0

        }

        this.url = `${process.env.REACT_APP_SERVER_URL}/api/Elastic/da_inviare`
    }

    componentDidMount() {
        this.fetchElasticRecords()
    }


    fetchElasticRecords() {
        this.setState({ loading: true })
        axios.get(this.url).then((response) => {
            console.log(response)

            this.setState({ loading: false })


            if (response.data.hits !== undefined) {

                let source_data = response.data.hits.hits.map((i) => {
                    i._source.id = i._id;
                    return i._source;
                });
                this.setState({ fileList: source_data }, () => console.log(this.state.fileList))
            }

        })
    }



    async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    waitFor(ms) { new Promise(r => setTimeout(r, ms)) };

    uploadList() {

        this.resetMessages()

        this.setState({ bills_in_process: [], currentErrorProgress: 0, currentSuccessProgress: 0, currentErrorCount: 0, currentSuccessCount: 0 })
        let fatture_da_inviare = this.state.fileList;

        // first only filter the ones that are with checked true
        let filtered = fatture_da_inviare.filter((fattura) => fattura.checked === true);

        this.setState({ loadingAruba: true })

        this.asyncForEach(filtered, async (fattura, index) => {
            await this.uploadInvoiceAruba(fattura)
        }).then(() => this.setState({ loadingAruba: false }))

        /*
        filtered.forEach((fattura, index) => {

            setTimeout(() => {
                try {
                    this.setState({ loadingAruba: true })
                    this.uploadInvoiceAruba(fattura)
                } catch (e) {
                    return;
                }
            }, 500 * index);


        });
        */
    }


    // SINGLE FILE OBJECT TO SEND ONLY ACCEPTS XML

    resetMessages() {

        let newArray = [...this.state.fileList];

        newArray.forEach(file => {
            file.message = ''
        });

        this.setState({ fileList: newArray })

    }

    renderFileListWithCheckbox() {

        //

        return this.state.fileList.map((fattura, index) =>
            (
                <div key={fattura.name}>
                    <Checkbox checked={fattura.checked} onChange={(e) => this.onCheckBoxChange(e, index)} disabled={fattura.disabled} >{fattura.name}</Checkbox>
                    <p id="invioFatturaMessage" style={{ color: fattura.color }}> {fattura.message} </p>
                </div>)
        )
    }


    checkAll() {
        console.log("im suppose to check all")

        let fileList = this.state.fileList;

        let checked_all = []

        fileList.forEach(file => {
            // console.log(file)        
            if (file.disabled === true) {
                return;
            }

            if (file.checked !== undefined) {
                file.checked = true;
                checked_all.push(file)
            } else {
                file.checked = true;
                checked_all.push(file)
            }
        });
        this.setState({ fileList: checked_all })
    }

    uncheckAll() {
        let fileList = this.state.fileList;

        let checked_all = []

        fileList.forEach(file => {

            if (file.disabled === true) {
                return;
            }
            // console.log(file)        
            if (file.checked !== undefined) {
                file.checked = false;
                checked_all.push(file)
            } else {
                file.checked = false;
                checked_all.push(file)
            }
        });
        this.setState({ fileList: checked_all })
    }

    onCheckBoxChange(e, index) {

        let newArray = [...this.state.fileList];

        let item_to_change = { ...newArray[index] }

        item_to_change.checked = e.target.checked;

        newArray[index] = item_to_change;

        this.setState({ fileList: newArray })
    }

    deleteFattura(id) {

        // remove it from elasticSearch
        axios.delete(this.url + `?id=${id}`).then((response) => {
            console.log(response)
            //let filtered = this.state.fileList.filter((fattura) => fattura.id !== id);
            //this.setState({ fileList: filtered })
        })

    }


    async uploadInvoiceAruba(fattura) {

        console.log(fattura)

        this.setState({ activeFattura: fattura })
        let payload = fattura;

        let url = `${process.env.REACT_APP_SERVER_URL}/api/fattura/fattura/send_fattura`

        //        this.setState({ loadingAruba: true })


        let response = await axios.post(url, payload).catch((error) => {

            let outcome = {
                fattura: fattura,
                message: error.message
            }

            this.setState({ bills_in_process: this.state.bills_in_process.concat(outcome) })
        });

        this.setState({ message: response.data.errorDescription });

        let outcome = {
            fattura: fattura,
            message: response.data.errorDescription
        }

        this.setState({ currentProgress: Math.ceil(this.state.currentProgress + 1 * (100 / this.state.fileList.length)) })

        fattura.message = response.data.errorCode + " - " + response.data.errorDescription;

        console.log(console.log(response.data.errorCode))

        console.log(this.state.loadingAruba);

    // if its successfull disable it
        if (response.data.errorCode === "0000") {
            fattura.checked = false
            fattura.disabled = true
            fattura.color = "green"
            // instantly delete from DB
            this.deleteFattura(fattura.id)

            this.setState({
                currentSuccessProgress: Math.ceil(this.state.currentSuccessProgress + 1 * (100 / this.state.fileList.length)),
                currentSuccessCount: this.state.currentSuccessCount + 1,
            })

        } else {
            this.setState({
                currentErrorProgress: Math.ceil(this.state.currentErrorProgress + 1 * (100 / this.state.fileList.length)),
                currentErrorCount: this.state.currentErrorCount + 1,
            })
            fattura.color = "red"
        }

        console.log(fattura)

        this.setState({ bills_in_process: this.state.bills_in_process.concat(outcome) })

    }

    render() {
        return (
            <div>

                <div id="percentageDivContainer" >

                    <div id="checkAllBtnContainer">
                        <Button id="selezionaAllBtn" type="primary" onClick={() => this.checkAll()}><i className="fas fa-check-square"></i> Seleziona Tutte </Button>
                        <Button id="deselezionaAllBtn" type="primary" onClick={() => this.uncheckAll()}><i className="far fa-square"></i> Deseleziona Tutte </Button>

                    </div>

                    <div id="percentageContainer">
                        <Progress id="loadingFatture_rosso" type="circle" strokeColor="red" percent={this.state.currentErrorProgress} format={(percent) => this.state.currentErrorCount} width={80} />
                        <Progress id="loadingFatture_verde" type="circle" strokeColor="green" percent={this.state.currentSuccessProgress} format={(percent) => this.state.currentSuccessCount} width={80} />
                    </div>

                    <div id="invioFinalBtnContainer">
                        {
                            this.state.loadingAruba ? <div> Non chiudere il browser durante l'invio <Icon type="loading" style={{ fontSize: 24 }} spin /> </div>
                                :
                                <Button id="inviaFatturBtnInvio" disabled={this.state.loadingAruba} onClick={() => this.uploadList()} type="primary">
                                    Invia Fattura/e
                                    <i className="fas fa-paper-plane" />
                                </Button>
                        }
                    </div>


                </div>


                <div id="uploadedAndReadyContainer">
                    {this.state.loading ? <Icon type="loading" style={{ fontSize: 24 }} spin /> : <div> {this.renderFileListWithCheckbox()} </div>}
                </div>





            </div>
        )
    }
}
