import axios from "axios";
import React, { Component } from "react";
import "../sass/FatturaCard.scss";

export default class FatturaCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pointerEvents: "all"
    };
  }

  async fetchHTMLpreview() {
    let new_url = `${process.env.REACT_APP_SERVER_URL}/api/fattura/one_pdf?fattura_id=${this.props.fattura._source.id}`;
    this.props.setLoadingRightPane(true);
    await axios
      .get(new_url)
      .then(response => {
        // console.log(response.data);
        this.props.setHtmlPreview(response.data, false);
      })
      .catch(error => {
        console.log(error);
        this.props.setHtmlPreview("error", false);
      });
  }

  async fetchHTMLministeriale() {
    this.props.setLoadingRightPane(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/fattura/ministeriale?id=${this.props.fattura._source.id}`
      )
      .then(response => {
        this.props.setHTMLMinisteriale(response.data, false);
      })
      .catch(error => {
        console.log(error);
        this.props.setHtmlPreview("error", false);
      });
  }

  renderStatusIcon(status) {
    switch (status) {
      case "Ricevuta":
        return <i className="fas fa-check cardIconsRightX" />;
      case "Consegnata":
        return <i className="fas fa-check cardIconsRightX" />;
      case "Non consegnata":
        return <i className="fas fa-exclamation cardIconsRightX" />;
      case "Inviata":
        return <i id="icon_inviata" className="fas fa-check cardIconsRightX" />;
      case "Scartata":
        return <i className="fas fa-trash cardIconsRightX" />;
      case "Decorrenza termini":
        return <i className="fas fa-exclamation cardIconsRightX" />;
      case "Accettata":
        return <i className="fas fa-thumbs-up cardIconsRightX" />;
      case "Rifiutata":
        return <i className="fas fa-thumbs-down cardIconsRightX" />;
      case "Errore elaborazione":
        return <i className="fas fa-times cardIconsRightX" />;
      case "Recapito Impossibile":
        return <i className="fas fa-times cardIconsRightX" />;
      default:
        return <i className="fas fa-exclamation cardIconsRightX" />;
    }
  }

  renderStatus() {
    let statusString = this.props.fattura._source.invoices[
      this.props.fattura._source.invoices.length - 1
    ].status;
    return (
      <div>
        {this.renderStatusIcon(statusString)}
        <b>{statusString}</b>
      </div>
    );
  }

  renderDataDocumento() {
    return this.props.fattura._source.invoices[0].invoiceDate.substring(0, 10);
  }

  onFatturaCardClick() {
    let id = this.props.fattura._source.id;

    this.setState({ loadingRightPane: true });

    this.props.updateSelectedXML(this.props.fattura);

    console.log(this.props.fattura._source);

    this.fetchHTMLpreview();

    this.fetchHTMLministeriale();

    if (
      this.props.fattura._source.docType === "in" &&
      this.props.fattura._source.read === undefined
    ) {
      this.markAsRead(this.props.fattura._source, id);
    }
  }
  markAsRead(fattura, id) {
    // disable card
    //this.setState({ pointerEvents: 'none' })

    let url = `${process.env.REACT_APP_SERVER_URL}/api/fattura/mark_as_read?fattura_id=${id}`;

    // mark as read in the server
    axios({
      method: "get",
      url: url
    })
      .then(response => {
        fattura.read = true;
        let currentMonth = this.props.currentMonth;
        let currentYear = this.props.currentYear;


        /*
        A map() creates an array, so a return is expected for all code paths (if/elses).
        If you don't want an array or to return data, use forEach instead.
        */

        // or you could simply just return null

        this.props.fatture_per_month.forEach((month_record, index) => {
          let date = new Date(month_record.key_as_string);

          let month = (date.getMonth() + 1).toString();
          let padded_month = month.padStart(2, "0");
          let year = date.getFullYear().toString();

          if (padded_month === currentMonth && year === currentYear) {
            this.props.updateFatturePerMonth(index);
          }
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  isoDateToShortDate(iso_date) { }

  renderLetta() {
    return (
      <div id="lettaIcon">
        <i className="fas fa-eye cardIconsLeft" /> Letta
      </div>
    );
  }

  renderNonLetta() {
    return (
      <div id="nonLettaIcon">
        <i className="fas fa-eye-slash cardIconsLeft" /> Non Letta{" "}
      </div>
    );
  }

  renderStampata() {
    return (
      <div id="lettaEStampataIcon">
        <i className="fas fa-check-double cardIconsLeft" /> Stampata
      </div>
    );
  }

  renderSenderOrReceiver() {

    if (this.props.fattura._source.docType === "in") {
      return (
        <div>
          {this.props.fattura._source.sender.description}
          <i className="fas fa-building cardIconsLeft" />
        </div>
      )
    } else {
      return (<div>
        {this.props.fattura._source.receiver.description}
        <i className="fas fa-file-import cardIcons cardIconsRight" />
      </div>)
    }
  }

  conditionalRendering() {
    if (this.props.fattura._source.docType === "in") {
      let read = this.props.fattura._source.read;
      let printed = this.props.fattura._source.printed;

      if (read === undefined) {
        return this.renderNonLetta();
      }

      if (read !== undefined && printed !== undefined) {
        return this.renderStampata();
      }

      if (read !== undefined) {
        return this.renderLetta();
      }
    }
  }

  render() {
    return (
      <div style={{ pointerEvents: this.state.pointerEvents }}>
        <div
          className={
            this.props.selectedFattura === this.props.selectedFatturaId
              ? "containerFatturaCard containerFatturaCardACTIVE"
              : "containerFatturaCard"
          }
          onClick={() => {
            this.onFatturaCardClick();
          }}
          key={this.props.fattura._source.id}
        >
          <div id="firstRowNG">

            <div id="numeroFattura">
              <b>
                <i className="fas fa-hashtag cardIconsLeft" />{" "}
                {this.props.fattura._source.invoices[0].number}
              </b>
            </div>

            <div id="creationDateFattura">
              {this.renderDataDocumento()}
              <i className="fas fa-edit cardIconsLeft" />
            </div>

          </div>
          <div id="secondRowNG">
            <div id="senderFattura">
              {this.props.fattura._source.filename}
            </div>
            <div id="receiverFattura">
              {this.renderSenderOrReceiver()}
            </div>
          </div>
          <div id="fourthRowNG">
            <div style={{ width: '130px' }}>
              {this.conditionalRendering()}
            </div>

            <div id="statoFattura">{this.renderStatus()}</div>

            <div id="lastUpdateDateDateFattura">
              <i className="fas fa-sync-alt cardIcons cardIconsRight" />
              {this.props.fattura._source.creationDate.substring(0, 10)}
            </div>{" "}


          </div>
        </div>
      </div>
    );
  }
}
