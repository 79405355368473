import { Modal } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { NavDropdown, Spinner } from "react-bootstrap";
import "../../sass/ActionButtons.scss";

export default class Notifiche extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      loading_notifications: false,
      notifica_html: "",
      show_single_notification: false
    };

    this.fetchNotifiche = this.fetchNotifiche.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleClose() {
    this.setState({ show_single_notification: false });
  }

  async handleOpen() {
    await this.fetch_html();
    this.setState({ show_single_notification: true });
  }

  async fetch_html() {
    // Send a POST request
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_URL}/api/fattura/notifiche/render_notifica_html`,
      data: {
        docType: `${this.state.notification.docType}`,
        file: `${this.state.notification.file}`
      }
    }).then(response => {
      //console.log(response.data)
      this.setState({ notifica_html: response.data });
    });
  }

  async fetchNotifiche() {
    this.setState({ loading_notifications: true });

    let filename = this.props.filename;
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/fattura/notifiche/getNotifiche?filename=${filename}`
      )
      .then(response => {
        //console.log(response.data.notifications)
        this.setState({
          notifications: response.data,
          loading_notifications: false
        });
      });
  }

  render_single_notification_modal() {
    if (this.state.show_single_notification === true) {
      return (
        <Modal
          title="Notifica"
          visible={this.state.show_single_notification}
          onOk={this.handleClose}
          onCancel={this.handleClose}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.notifica_html
            }}
          ></div>
        </Modal>
      );
    }
  }

  render() {
    const notificationList = this.state.notifications.map(notification => {
      return (
        <NavDropdown.Item
          onClick={() =>
            this.setState({ notification: notification }, () =>
              this.handleOpen()
            )
          }
          key={notification.filename}
          className="notificheButtons"
        >
          {notification.notificationDate}
          {notification.filename}
          {notification.MT}
        </NavDropdown.Item>
      );
    });

    return (
      <div>
        {this.render_single_notification_modal()}

        <NavDropdown
          onClick={this.fetchNotifiche}
          // onSelect={notifichePopup}
          onSelect={this.showModal}
          title={
            <span>
              <i className="fas fa-envelope-open-text" />
              {/* <img className="btnIconsRP" src="https://image.flaticon.com/icons/svg/1040/1040218.svg" style={{ height: "18px" }} /> */}
              Notifiche{" "}
            </span>
          }
          className="navToBtn notificheButtonRightPane"
          id="basic-nav-dropdown"
        >
          {this.state.loading_notifications
            ? true && (
                <Spinner
                  id="spinnerLoading"
                  animation="border"
                  variant="primary"
                />
              )
            : notificationList}
        </NavDropdown>
      </div>
    );
  }
}
