import React, { Component } from 'react'
import ActionButtons from './Toolbar/ActionButtons';




export default class Markup extends Component {

    constructor(props) {
        super(props)

        this.state = {

        }
//        console.log(this.props);
    }

    createMarkup = () => {
        if (this.props.statePreviewDFBtn) {
            if (this.props.defaultPreviewHTML === "error") {
                return (
                    <div>
                        <ActionButtons downloadXML={this.props.downloadXML}
                            updatestatePreviewDFBtn={this.props.updatestatePreviewDFBtn}
                            selectedXML={this.props.selectedXML}
                            componentRef={this.componentRef}
                        />

                        <h2>Error, probably using Encoding windows-1252</h2>

                    </div>
                );
            }

            if (this.props.defaultPreviewHTML !== "") {
                return (
                    <div>
                        <ActionButtons downloadXML={this.props.downloadXML}
                            updatestatePreviewDFBtn={this.props.updatestatePreviewDFBtn}
                            selectedXML={this.props.selectedXML}
                            componentRef={this.componentRef}
                        />
                        <div className="PDFView"
                            ref={el => (this.componentRef = el)}
                            dangerouslySetInnerHTML={{
                                __html: this.props.defaultPreviewHTML
                            }}
                        />
                    </div>
                );
            }
        } else {

            return (
                <div>
                    <ActionButtons downloadXML={this.props.downloadXML}
                        updatestatePreviewDFBtn={this.props.updatestatePreviewDFBtn}
                        selectedXML={this.props.selectedXML}
                        componentRef={this.componentRef}

                    />
                    <div
                        ref={el => (this.componentRef = el)}
                        dangerouslySetInnerHTML={{
                            __html: this.props.minPreviewHTML
                        }}
                    />
                </div>
            );

        }
    };


    render() {
        return (
            <div>
                { this.createMarkup() }
            </div>
        )
    }
}
