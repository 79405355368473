import {Drawer, Form, Steps} from 'antd';
import React from 'react';
import {Button} from 'react-bootstrap';
import FileUpload from './FileUpload';
import InvioStep from './InvioStep';
import '../../sass/Invio.scss';
import {AuthContext} from '../../../context/auth';

const {Step} = Steps;
class InvioWithoutForm extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      fattura_to_send: '',
      message: '',
      fileList: [],
      elasticArray: [],
      loading: false,
      activeFatturaState: '',
      activeFattura: '',
      bills_in_process: [],
      current: 0,
    };

    this.disableAvanti = this.disableAvanti.bind(this);
  }

  componentDidMount() {
    if(window.location.search === '?open') {
      this.setState({visible: true});
    }

  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = e => {
    this.setState({
      visible: false,
      message: '',
    });
  };

  disableAvanti() {
    this.setState({loading: true});
  }

  enableAvanti() {
    this.setState({loading: false});
  }

  next() {
    const current = this.state.current + 1;
    this.setState({current});
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({current});
  }

  // this button is for the fornitore, he can only see the button when he is the activeUser
  // otherwise when he this.context.username == this.context.immutable_username
  InviaButton() {
    console.log(this.context.username);
    console.log(this.context.immutable_username);

    if (this.context.role === 'admin') {
      return (
        <Button
          disabled={this.props.credit === 0}
          onClick={this.showDrawer}
          className="navBtn"
          variant="outline-info"
          id="invioMainBtn">
          <i className="fas fa-paper-plane" />
          Invia Fatture
        </Button>
      );
    }

    if (this.context.username === this.context.immutable_username) {
      return (
        <Button
          disabled={this.props.credit === 0 || this.props.attivo !== true}
          onClick={this.showDrawer}
          className="navBtn"
          variant="outline-info"
          id="invioMainBtn">
          <i className="fas fa-paper-plane" />
          Invia Fatture
        </Button>
      );
    } else {
      return <div></div>;
    }
  }

  render() {
    const {current} = this.state;

    const steps = [
      {
        title: 'Caricamento',
        content: (
          <FileUpload
            disableAvanti={() => this.disableAvanti()}
            enableAvanti={() => this.enableAvanti()}
          />
        ),
      },
      {
        title: 'Invio',
        content: <InvioStep />,
      },
    ];

    return (
      <div>
        {this.InviaButton()}
        <Drawer
          id="drawerInviaFattura"
          title="Invia Fattura"
          width={720}
          onClose={this.onClose}
          visible={this.state.visible}>
          <div>
            <Steps current={current}>
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
              {current < steps.length - 1 && (
                <Button
                  disabled={this.state.loading}
                  id="avantiBtnInvio"
                  type="primary"
                  onClick={() => this.next()}>
                  AVANTI
                  <i className="fas fa-arrow-circle-right"></i>
                </Button>
              )}
              {current > 0 && (
                <Button id="indietroBtnInvio" onClick={() => this.prev()}>
                  <i className="fas fa-arrow-circle-left"></i>
                  INDIETRO
                </Button>
              )}
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

const Invio = Form.create()(InvioWithoutForm);
export default Invio;
