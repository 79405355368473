import React, { Component } from "react";
import { Nav, Navbar } from "react-bootstrap";
import "../../sass/ActionButtons.scss";
import Allegati from "./Allegati";
import EsportaXML from "./EsportaXML";
import Notifiche from "./Notifiche";
import Preview from "./Preview";
import ScaricaZip from "./ScaricaZip";
import Stampa from "./Stampa";
export default class ActionButtons extends Component {
  render() {
    return (
      <div className="rightPane">
        <Navbar expand="lg" className="navRightPane">
          <Nav className="mr-auto">
            <EsportaXML filename={this.props.selectedXML._source.filename} />

            <Stampa source={this.props.selectedXML._source} />

            <ScaricaZip source={this.props.selectedXML._source} />

            <Preview
              updatestatePreviewDFBtn={this.props.updatestatePreviewDFBtn}
            />

            <Notifiche filename={this.props.selectedXML._source.filename} />

            <Allegati filename={this.props.selectedXML._source.filename} />
          </Nav>
        </Navbar>
      </div>
    );
  }
}
