import LinearProgress from "@material-ui/core/LinearProgress";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Pagination, Spin, Button } from "antd";
import axios from "axios";
import PrettifyXML from "prettify-xml";
import React, { Component } from "react";
import FileDownload from "react-file-download";
import { AuthContext } from "../../context/auth";
import "../sass/FatturaList.scss";
import FatturaCard from "./FatturaCard";
import FatturaCardMulti from "./FatturaCardMulti";
import Markup from "./Markup";
import search from "./search";

export default class FatturaList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      activeUser: "",

      fatture: [],

      defaultPreviewHTML: "",
      statePreviewDFBtn: true,
      minPreviewHTML: "",
      selectedFattura: "",
      selectedXML: "",
      loadingRightPane: false,

      currentPage: 1,
      totalElements: 0,

      loading: false
    };

    this.changePage = this.changePage.bind(this);
    this.itemRender = this.itemRender.bind(this);

    this.updateSelectedXML = this.updateSelectedXML.bind(this);
    this.downloadXML = this.downloadXML.bind(this);
    this.updatestatePreviewDFBtn = this.updatestatePreviewDFBtn.bind(this);

    this.setHTMLMinisteriale = this.setHTMLMinisteriale.bind(this);
    this.setHtmlPreview = this.setHtmlPreview.bind(this);
    this.setLoadingRightPane = this.setLoadingRightPane.bind(this);
  }

  componentDidMount() {
    // this.refresh()
    this.fetchSearchFatture();
  }

  itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <Button type="link">Indietro <NavigateBeforeIcon /> </Button>;
    }
    if (type === "next") {
      return <Button type="link">Avanti <NavigateNextIcon /> </Button>;
    }
    return originalElement;
  }

  componentDidUpdate(prevProps, prevState) {
    let month_changed = prevProps.currentMonth !== this.props.currentMonth;
    let year_changed = prevProps.currentYear !== this.props.currentYear;
    let direction_changed = prevProps.direction !== this.props.direction;
    let statusFilter_changed =
      prevProps.statusFilter !== this.props.statusFilter;
    let searchText_changed = prevProps.searchText !== this.props.searchText;
    let fatture_per_month_changed =
      prevProps.fatture_per_month !== this.props.fatture_per_month;

    // these are the filters clienti / fornitori
    let clienteFilter_change =
      prevProps.clienteFilter !== this.props.clienteFilter;
    let fornitoreFilter_change =
      prevProps.fornitoreFilter !== this.props.fornitoreFilter;

    if (fatture_per_month_changed) {
      console.log("you read a fattura, or you printed it");
      return;
    }

    if (prevProps.activeUser !== this.props.activeUser) {
      // the fornitore or admin changed the user
      this.refresh();
    }

    // only reset the currentPage to 1 when changing the month, the year or the direction, !!! not the page, leave the filter when you are changing the filter
    if (
      month_changed ||
      year_changed ||
      direction_changed ||
      statusFilter_changed ||
      clienteFilter_change ||
      fornitoreFilter_change ||
      searchText_changed
    ) {
      if (direction_changed || month_changed || year_changed) {
        this.setState({ currentPage: 1, defaultPreviewHTML: "" }, async () => {
          this.refresh();
        });
      } else {
        // do it without changing the currentPage
        this.refresh();
      }
    }
  }

  async refresh() {
    console.log("------refreshing fatture ------ ");
    await this.fetchSearchFatture();
    let distilled_fatture = search(
      this.props.searchText,
      this.props.statusFilter,
      this.state.fatture
    );

    console.log("should refresh yoooooooooooooo ");
    console.log(this.props.clienteFilter);

    this.filterByType(distilled_fatture);
  }

  // type can be either fornitori or clienti , so its based on the direction, in or out
  // this.props.clienteFilter
  // this.props.fornitoreFilter
  filterByType(distilled_fatture) {
    if (this.props.direction === "in") {
      console.log("yoga in ---------");

      if (this.props.fornitoreFilter === "tutti") {
        this.setState({ fatture: distilled_fatture });
        return;
      } else {
        console.log(distilled_fatture);

        let distilled_by_fornitore = distilled_fatture.filter(fattura => {
          return (
            fattura._source.sender.description === this.props.fornitoreFilter
          );
        });

        console.log(distilled_by_fornitore);

        this.setState({ fatture: distilled_by_fornitore });
        return;
      }
    }

    if (this.props.direction === "out") {
      console.log("yoga out ---------");

      if (this.props.clienteFilter === "tutti") {
        this.setState({ fatture: distilled_fatture });
        return;
      } else {
        let distilled_by_cliente = distilled_fatture.filter(fattura => {
          return (
            fattura._source.receiver.description === this.props.clienteFilter
          );
        });

        this.setState({ fatture: distilled_by_cliente });
        return;
      }
    }
  }

  async fetchSearchFatture() {
    // this filter are set by the Temporal Logic component
    const {
      currentMonth,
      currentYear,
      currentDateType,
      direction
    } = this.props;

    this.setState({ loading: true });

    let from = (this.state.currentPage - 1) * 500;

    // FEATURE of duplicates ISSUE #22
    // scartata and normal bills should be grouped

    let already_in = [];

    let duplicates = [];

    let query_url = "";

    if (currentMonth === "0") {
      query_url = `${process.env.REACT_APP_SERVER_URL}/api/fattura/invoice/${direction}/findSearchByUsername?startMonth=${currentYear}-01-01&endMonth=${currentYear}-12-31&page=${from}&dateType=${currentDateType}`;
    } else {
      var lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();

      query_url = `${process.env.REACT_APP_SERVER_URL}/api/fattura/invoice/${direction}/findSearchByUsername?startMonth=${currentYear}-${currentMonth}-01&endMonth=${currentYear}-${currentMonth}-${lastDayOfMonth}&page=${from}&dateType=${currentDateType}`;
    }

    await axios.get(query_url).then(response => {
      let fatture = response.data.hits;
      console.log(fatture.length);
      fatture.forEach((bill, index) => {
        let fattura_numero = bill.sort[1];

        // this is the Number of the fattura
        // 1051
        if (already_in.includes(fattura_numero)) {
          //duplicate_of.duplicate_number = true;
          // to the duplicate we add a special field
          console.log("detected a duplicate");

          duplicates.push(fattura_numero);
        } else {
          already_in.push(fattura_numero);
        }

        // save the array
        bill.fattura_status =
          bill._source.invoices[bill._source.invoices.length - 1].status;
        // console.log(bill);
      });

      let duplicates_bills = [];
      fatture.forEach(bill => {
        if (duplicates.includes(bill.sort[1])) {
          bill.docNumber = bill.sort[1];
          // we get all the duplicates
          duplicates_bills.push(bill);
        }
      });

      console.log(duplicates_bills);

      let grouped_bois = this.groupBy(duplicates_bills, "docNumber");

      const bill_values = Object.values(grouped_bois);

      bill_values.forEach(group => {
        let freshest = "";
        // sort the group
        group.sort((a, b) =>
          new Date(a._source.creationDate) < new Date(b._source.creationDate)
            ? 1
            : -1
        );

        // the group is sorted by creationDate, now if there is a state of consegnata, that fattura will have to be moved at the beginning of the array
        // so that sorted_group[1] would always show either the freshest or move Consegnata to first position

        // check if there is a consegnata somewhere in the array, if Yes move it to the first position of the sorted_group
        group.forEach(function(item, i) {
          if (item.fattura_status === "Non consegnata") {
            group.splice(i, 1);
            group.unshift(item);
          }

          if (item.fattura_status === "Consegnata") {
            group.splice(i, 1);
            group.unshift(item);
          }
        });

        console.log(group[0].fattura_status);
        console.log(group[1].fattura_status);

        freshest = group[0];

        console.log(freshest);

        // now the freshest has the right condition, either the newest or has a state of validated,
        // next we want to put subfattura's under it, so we would render a mini list instead of normal FatturaCard
        // FatturaCard with SubCards

        // remove freshest from the group array
        group.shift();

        freshest.multi = true;
        freshest.sub_fatture = group;

        console.log(freshest);

        var filtered = fatture.filter(function(el) {
          if (el._id === freshest._id) {
            return true;
          } else {
            return el.sort[1] !== freshest.sort[1];
          }
        });

        fatture = filtered;

        // we want to remove the oldest one form being seen
        // let ref_date = Date(value._source.creationDate)
        // compare the creationDate and remove the oldest
      });

      // _source.receiver.description

      if (this.props.direction === "out") {
        let clienti = [];

        fatture.forEach(fattura => {
          clienti.push(fattura._source.receiver.description);
        });

        this.props.setClienti(clienti);
      }

      if (this.props.direction === "in") {
        let fornitori = [];

        fatture.forEach(fattura => {
          fornitori.push(fattura._source.sender.description);
        });

        this.props.setFornitori(fornitori);
      }

      // empty the array

      this.setState({
        fatture: fatture,
        totalElements: response.data.total.value,
        loading: false,
        statusFilter: ""
      });
    });
  }

  groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  newerFattura(fatture) {
    fatture.forEach(bill => {
      console.log(bill);
    });
  }

  changePage(page, pageSize) {
    this.setState({ currentPage: page }, async () => {
      console.log("only page changed");
      await this.fetchSearchFatture();

      let distilled_fatture = search(
        this.props.searchText,
        this.props.statusFilter,
        this.state.fatture
      );
      this.setState({ fatture: distilled_fatture });
    });
  }

  setHtmlPreview(defaultPreviewHTML, loadingRightPane) {
    this.setState({ defaultPreviewHTML, loadingRightPane });
  }

  setHTMLMinisteriale(minPreviewHTML, loadingRightPane) {
    this.setState({ minPreviewHTML, loadingRightPane });
  }

  setLoadingRightPane(loading) {
    this.setState({
      defaultPreviewHTML: "",
      minPreviewHTML: "",
      loadingRightPane: loading
    });
  }

  async downloadXML() {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/fattura/xml?filename=${this.state.selectedXML._source.filename}`
      )
      .then(response => {
        const prettifiedXml = PrettifyXML(response.data);
        FileDownload(prettifiedXml, this.state.selectedXML._source.filename);
      });
  }

  updateSelectedXML(selectedXML) {
    this.setState({ selectedXML });
  }

  updatestatePreviewDFBtn(statePreviewDFBtn) {
    this.setState({ statePreviewDFBtn });
  }

  renderFattura(fattura, index) {
    if (fattura.multi === true && fattura._source.docType === "out") {
      return (
        <div
          key={fattura._source.id}
          onClick={() => this.setState({ selectedFattura: fattura._source.id })}
        >
          <FatturaCardMulti
            index={index}
            selectedFattura={this.state.selectedFattura}
            selectedFatturaId={fattura._source.id}
            fattura={fattura}
            setHTMLMinisteriale={this.setHTMLMinisteriale}
            setHtmlPreview={this.setHtmlPreview}
            setLoadingRightPane={this.setLoadingRightPane}
            updateSelectedXML={this.updateSelectedXML}
            updateFatturePerMonth={this.props.updateFatturePerMonth}
            fatture_per_month={this.props.fatture_per_month}
            currentMonth={this.props.currentMonth}
            currentYear={this.props.currentYear}
          />
        </div>
      );
    } else {
      return (
        <div
          key={fattura._source.id}
          onClick={() => this.setState({ selectedFattura: fattura._source.id })}
        >
          <FatturaCard
            index={index}
            selectedFattura={this.state.selectedFattura}
            selectedFatturaId={fattura._source.id}
            fattura={fattura}
            setHTMLMinisteriale={this.setHTMLMinisteriale}
            setHtmlPreview={this.setHtmlPreview}
            setLoadingRightPane={this.setLoadingRightPane}
            updateSelectedXML={this.updateSelectedXML}
            updateFatturePerMonth={this.props.updateFatturePerMonth}
            fatture_per_month={this.props.fatture_per_month}
            currentMonth={this.props.currentMonth}
            currentYear={this.props.currentYear}
          />
        </div>
      );
    }
  }

  render() {
    const fatture = this.state.fatture.map((fattura, index) =>
      this.renderFattura(fattura, index)
    );

    return (
      <div>
        <div className="paginationDiv">
          <Pagination
            current={this.state.currentPage}
            total={this.state.totalElements}
            itemRender={this.itemRender}
            pageSize={500}
            onChange={this.changePage}
          />
        </div>

        <div id="fattureCardPreviewContainer">
          <div id="leftPaneContainerCards">
            {this.state.loading ? true && <LinearProgress /> : fatture}
          </div>

          <div className="rightPaneContainer" id="rightPaneContainerPreview">
            {this.state.loadingRightPane ? (
              true && <Spin id="spinnerLoadingX" />
            ) : (
              <Markup
                downloadXML={this.downloadXML}
                updatestatePreviewDFBtn={this.updatestatePreviewDFBtn}
                selectedXML={this.state.selectedXML}
                defaultPreviewHTML={this.state.defaultPreviewHTML}
                statePreviewDFBtn={this.state.statePreviewDFBtn}
                minPreviewHTML={this.state.minPreviewHTML}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
