import React, { Component } from 'react'
import { Select } from "antd";
const { Option } = Select;


export default class StatusSelect extends Component {

    componentDidUpdate(prevProps, prevState) {
        console.log(prevProps);
        console.log(prevState);
    }


    StatusSelectOut() {
        return (<div className="filtraPerDiv">
            <p className="filtraPerP">Filtra per : </p>
            <Select
                className="filtraPerSelect"
                showSearch
                value={this.props.statusFilter}
                placeholder="Select a status you want to filter"
                optionFilterProp="children"
                onChange={this.props.changeFilterStatus}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                <Option value="tutte">Tutte</Option>
                <Option value="consegnata">Consegnata</Option>
                <Option value="non consegnata">Non consegnata</Option>
                <Option value="inviata">Inviata</Option>
                <Option value="scartata">Scartata</Option>
                <Option value="decorrenza termini">Decorrenza termini</Option>
                <Option value="accettata">Accettata</Option>
                <Option value="rifiutata">Rifiutata</Option>
                <Option value="errore elaborazione">Errore elaborazione</Option>
                <Option value="recapito impossibile">Recapito impossibile</Option>
            </Select>

        </div>)
    }


    StatusSelectIn() {
        return (
            <div className="filtraPerDiv">
                <p className="filtraPerP">Filtra per: </p>
                <Select
                    className="filtraPerSelect"
                    showSearch
                    value={this.props.statusFilter}
                    placeholder="Select a status you want to filter"
                    optionFilterProp="children"
                    onChange={this.props.changeFilterStatus}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value="ricevuta">Ricevuta</Option>
                    <Option value="read">Lette</Option>
                    <Option value="not_read">Non Lette</Option>

                </Select>
            </div>
        )
    }

    render() {

        return (
            <div>
                { this.props.direction === "in" ? this.StatusSelectIn() : this.StatusSelectOut()}
            </div>
        )
    }
}
