import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader
} from "@david.kucsai/react-pdf-table";
import { Document, Page, pdf, StyleSheet, Text } from "@react-pdf/renderer";
import React from "react";

// const dateFormat = "YYYY-MM-DD";

// const { Option } = Select;

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    fontSize: 8,
    padding: 20
  },
  title: {
    margin: 10,
    fontSize: 15,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center"
  },
  headers: {
    fontSize: 11,
    fontWeight: "bold",
    padding: 2
  },
  singleLine: {
    fontSize: 9,
    padding: 2
  },
  actualSingleLine: {
    display: "flex",
    flexDirection: "row"
  }
});



export default async function generateReport(direction, startMonth, endMonth, fatture ) {
    let direction_string = "";
    let cliente_fornitore_string = "";

    if (direction === "in") {
      direction_string = "ricevute";
      cliente_fornitore_string = "Fornitore";
    } else {
      direction_string = "inviate";
      cliente_fornitore_string = "Cliente";
    }

    const TableDoc = (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text style={styles.title}>
            Report delle fatture {direction_string} pdf dal{" "}
            {startMonth} al {endMonth}
          </Text>

          <Table data={fatture}>
            <TableHeader>
              <TableCell styles={styles.headers}>Numero</TableCell>
              <TableCell styles={styles.headers}>Data</TableCell>
              <TableCell styles={styles.headers}>Ricez Sdi</TableCell>
              <TableCell styles={styles.headers}>Consegna</TableCell>
              <TableCell styles={styles.headers} weighting={0.3}>
                {cliente_fornitore_string}
              </TableCell>
              <TableCell styles={styles.headers}>Consegna</TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                styles={styles.singleLine}
                getContent={r => r.invoices[0].number}
              />
              <DataTableCell
                styles={styles.singleLine}
                getContent={r =>
                  new Date(r.invoices[0].invoiceDate).toLocaleDateString(
                    "it-IT"
                  )
                }
              />
              <DataTableCell
                styles={styles.singleLine}
                getContent={r =>
                  new Date(r.creationDate).toLocaleDateString("it-IT")
                }
              />
              <DataTableCell
                styles={styles.singleLine}
                getContent={r =>
                  new Date(r.lastUpdate).toLocaleDateString("it-IT")
                }
              />
              <DataTableCell
                styles={styles.singleLine}
                weighting={0.3}
                getContent={r => {
                  if (r.docType === "out") {
                    return r.receiver.description;
                  } else {
                    return r.sender.description;
                  }
                }}
              />
              <DataTableCell
                styles={styles.singleLine}
                getContent={r => r.invoices[r.invoices.length - 1].status}
              />
            </TableBody>
          </Table>
        </Page>
      </Document>
    );

    const blob = await pdf(TableDoc).toBlob();

    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Report delle fatture ${direction_string} pdf dal ${startMonth} al ${endMonth}`;
    link.click();
}