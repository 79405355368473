import Fuse from 'fuse.js';

export default function search(search_query, statusFilter, fatture) {


    // if the status filter bar is set, apply it exclusively and feed the result to the next search query
    if (statusFilter && statusFilter !== 'tutte') {


        let filterStatusResult = []

        // console.log(statusFilter);

        if (statusFilter === 'read') {
            filterStatusResult = fatture.filter(fattura => fattura._source.read === true);
        } else if (statusFilter === 'not_read') {
            filterStatusResult = fatture.filter((fattura) => fattura._source.read === undefined)
        } else {

            console.log("inside statusFilter")
            const options = {

                threshold: 0,
                location: 0,
                distance: 1,
                maxPatternLength: 32,
                minMatchCharLength: 4,
                keys: [
                    "fattura_status",
                ]
            };
            let fuse = new Fuse(fatture, options); // "list" is the item array
            filterStatusResult = fuse.search(statusFilter);
        }

        if (search_query) {
            // console.log("statusFilter true => searchText true")

            const options = {
                threshold: 0.4,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 4,
                keys: [
                    "_source.filename",
                    "_source.sender.description",
                    "_source.receiver.description",
                    "_source.sender.fiscalCode",
                    "_source.receiver.fiscalCode",
                ]
            };
            var fuse2 = new Fuse(filterStatusResult, options); // "list" is the item array
            var result2 = fuse2.search(search_query);
            return result2;

        } else {
            console.log("statusFilter true => searchText empty")
            return filterStatusResult
        }
    } else {
        // console.log(search_query)
        // if the status bar filter is not active, only check for the searchText field and query if there is anything interesting there
        if (search_query) {
            console.log("statusFilter false => searchText true")
            var options = {
                threshold: 0.4,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 4,
                keys: [
                    "_source.filename",
                    "_source.sender.description",
                    "_source.receiver.description",
                    "_source.sender.fiscalCode",
                    "_source.receiver.fiscalCode",
                ]
            };
            var fuse = new Fuse(fatture, options); // "list" is the item array
            var result = fuse.search(search_query);
            return result;

        } else {

            // console.log("statusFilter false => searchText false ")
            return fatture
        }


    }


}