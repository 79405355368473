import axios from "axios";
import React, { Component } from "react";
import { Nav } from "react-bootstrap";

export default class Stampa extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.downloadOne = this.downloadOne.bind(this);
  }

  markAsPrinted(fattura) {
    let url = `${process.env.REACT_APP_SERVER_URL}/api/fattura/mark_as_printed?fattura_id=${this.props.source.id}`;

    axios({
      method: "get",
      url: url
    })
      .then(response => {
        fattura.printed = true;
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  async downloadOne() {
    // need the whole source of the selectedXML
    this.setState({ loading: true, error: "" });
    let url = `${process.env.REACT_APP_SERVER_URL}/api/fattura/download_one_pdf?fattura_id=${this.props.source.id}`;

    await axios({
      method: "get",
      url: url,
      responseType: "arraybuffer"
    })
      .then(response => {
        // mark as printed

        console.log(response);

        this.markAsPrinted(this.props.source);

        console.log(response);
        this.setState({ loading: false });

        let blob = new Blob([response.data], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.props.source.filename}.pdf`;
        link.click();
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: "non ci sono fatture in questa data"
        });
      });
  }

  render() {
    return (
      <Nav.Link
        onClick={this.downloadOne}
        className="navToBtn stampaButtonRightPane"
      >
        <p href="#">
          <i className="fas fa-print" />
          Stampa
        </p>
      </Nav.Link>
    );
  }
}
