import React, { Component } from "react";
import axios from "axios";

export const AuthContext = React.createContext();

class AuthProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      immutable_token: localStorage.getItem("immutable_token"),
      role: localStorage.getItem("role"),
    };

    // next major update will smoothly upgrade these users
    if (
      !localStorage.getItem("immutable_refresh_token") &&
      localStorage.getItem("immutable_token")
    ) {
      localStorage.clear();
      window.location = `${process.env.REACT_APP_SELF}`;
    }

    if (this.state.immutable_token) {
      this.firstSignIn(this.state.immutable_token);
    }
  }

  firstSignIn = (token) => {
    this.setAxiosDefaultToken(token);
    this.fetchOnlyFirstTime();

    console.log("done with firstSignIn -------- ");
  };

  changeActiveToken = async (username, password) => {
    console.log(username);
    console.log(password);
    // set username and user info context
    // execute signin to get the token and fetch the profile
    await this.signin(username, password);
    await this.fetchUserData();
  };

  setAxiosDefaultToken = (token) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  };

  signin = async (username, password) => {
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/user/auth/signin`, {
        username: username,
        password: password,
      })
      .then(async (response) => {
        console.log("signin ------------- ");
        // set axios token

        // empty any subclient tokens set previously and delete their access tokens from the db, since they are not programmatically logged in anymore
        if (localStorage.getItem("subclient_token")) {
          let payload = {
            refreshToken: localStorage.getItem("subclient_refresh_token"),
            token: localStorage.getItem("subclient_token"),
          };
          await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/user/auth/signout_from_device`,
            payload
          );
        }

        let access_token = response.data.rawData;
        let refresh_token = response.data.refresh_token;
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + access_token;
        localStorage.setItem("subclient_token", access_token);
        localStorage.setItem("subclient_refresh_token", refresh_token);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async fetchOnlyFirstTime() {
    console.log("Fetching for the absolute first time");

    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/user/me`)
      .then((response) => {
        console.log(response);
        this.setState({
          username: response.data.username,
          immutable_username: response.data.username,
          immutable_azienda: response.data.azienda,
          partitaIva: response.data.partitaIva,
          credit: response.data.credit,
          role: response.data.role,
          mailing_list: response.data.mailing_list,
          azienda: response.data.azienda,
          attivo: response.data.attivo,
        });

        // only set if it's empty
        // if (localStorage.getItem("role") === null) {
          localStorage.setItem("role", response.data.role);
        // }

      });
  }

  fetchUserData() {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/user/me`)
      .then((response) => {
        console.log(response);
        this.setState({
          username: response.data.username,
          partitaIva: response.data.partitaIva,
          credit: response.data.credit,
          mailing_list: response.data.mailing_list,
          azienda: response.data.azienda,
          attivo: response.data.attivo,
        });
      });
  }

  logIn = (user) => {
    console.log("auth context login executed ---");

    console.log(user);
    // store the data in the global state
    this.setState({ immutable_token: user.rawData }, () => {
      this.firstSignIn(this.state.immutable_token);
      localStorage.setItem("immutable_token", user.rawData);
      localStorage.setItem("immutable_refresh_token", user.refresh_token);
    });
  };

  logOut = async () => {
    console.log("auth context logout executed ---");

    // delete the refresh token for the subclient on the db
    // if we have them
    if (localStorage.getItem("subclient_token")) {
      let deleteSubClientToken = {
        refreshToken: localStorage.getItem("subclient_refresh_token"),
        token: localStorage.getItem("subclient_token"),
      };

      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/user/auth/signout_from_device`,
        deleteSubClientToken
      );
    }

    let deleteRefreshToken = {
      refreshToken: localStorage.getItem("immutable_refresh_token"),
      token: localStorage.getItem("immutable_token"),
    };
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/user/auth/signout_from_device`,
      deleteRefreshToken
    );

    window.location = `${process.env.REACT_APP_SELF}`;

    localStorage.clear();
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          logIn: this.logIn,
          logOut: this.logOut,
          changeActiveToken: this.changeActiveToken,
          firstSignIn: this.firstSignIn,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export const Consumer = AuthContext.Consumer;
export const Provider = AuthProvider;
