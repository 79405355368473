import React, { Component } from "react";
import { Nav }  from "react-bootstrap";
import axios from 'axios'
import FileDownload from "react-file-download";
import PrettifyXML from "prettify-xml";

export default class EsportaXML extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.downloadXML = this.downloadXML.bind(this);
  }

  //TODO always rename the file and remove the p7m extension, not sure but i should check
  async downloadXML() {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/fattura/xml?filename=${this.props.filename}`
      )
      .then(response => {
        const prettifiedXml = PrettifyXML(response.data);
        FileDownload(prettifiedXml, this.props.filename);
      });
  }

  render() {
    return (
        <Nav.Link className="esportaButtonRightPane navToBtn">
          <p className="esportaButtonRightPane" onClick={this.downloadXML}>
            <i className="fas fa-file-excel" />
            Esporta XML
          </p>
        </Nav.Link>
    );
  }
}
