import { Icon, Button, Modal } from "antd";
import axios from "axios";
import React from "react";
import { AuthContext } from "../../../context/auth";

export default class FileUpload extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.state = {

      loadingAllegati: false,

      visible: false,
      fileList: [],
      loading: false,
      counter: 0,

      htmlPreview: '',

      errorMessage: "",

      tuplebois: []
    };
    this.url = `${process.env.REACT_APP_SERVER_URL}/api/Elastic/da_inviare`;


    this.addAllegato = this.addAllegato.bind(this)
  }

  componentDidMount() {
    // read them here
    // fetch the already existing on elasticsearch
    this.fetchElasticRecords();
    this.setState({ activeUser: this.context.immutable_username });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.context.role === "admin") {
      console.log(this.state.activeUser);
      console.log(this.context.username);

      if (this.context.username !== this.state.activeUser) {
        this.setState({ activeUser: this.context.username });
        this.fetchElasticRecords();
        console.log("something got updated");
      }
    }
  }

  async fetchElasticRecords() {
    this.setState({ loading: true });

    let response = await axios.get(this.url);

    console.log(response);

    this.setState({ loading: false });

    let source_data = response.data.hits.hits.map(i => {
      i._source.id = i._id;
      return i._source;
    });
    this.setState({ fileList: source_data });
  }

  overwriteIDSDI_aruba(string) {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(string, "text/xml");
    xmlDoc.getElementsByTagName("IdCodice")[0].textContent = "01879020517";

    let xmlString = new XMLSerializer().serializeToString(
      xmlDoc.documentElement
    );
    return xmlString;
  }

  // uploads the File Objects into an array
  async onUpload(event) {
    this.setState({ tuplebois: [] });

    console.log(event);
    console.log(event.target.files);

    if (event.target.value.length === 0) {
      console.log("Suspect Cancel was hit, no files selected.");
      return;
    }

    let files = event.target.files;

    let file_values = Object.values(files);

    for (let i = 0; i < file_values.length; i++) {
      const file_value = file_values[i];
      await this.readFile(file_value);

      // last element
      if (i === file_values.length - 1) {
        console.log(this.state.tuplebois.length);

        if (this.state.tuplebois.length !== 0) {
          this.error();
        }

        await this.fetchElasticRecords();

        // otherwise execute a refetch
      }
    }
  }

  async readFile(file_value) {
    const file = file_value;
    if (!file) return;

    const data = await new Response(file).text();

    await this.processFileContent(data, file_value);
  }

  b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(
        match,
        p1
      ) {
        return String.fromCharCode("0x" + p1);
      })
    );
  }

  async processFileContent(data, file) {
    // console.log(data);

    data = data.replace("ï»¿", "");

    let forced_aruba_idsdi = "";

    try {
      forced_aruba_idsdi = this.overwriteIDSDI_aruba(data);
    } catch (error) {
      let tupleBoi = {
        filename: file.name,
        errorMessages: ["Fattura non valida"]
      };
      this.setState({ tuplebois: [...this.state.tuplebois, tupleBoi] });

      return;
    }


    let brandonscript64 = this.b64EncodeUnicode(forced_aruba_idsdi)

    // console.log(brandonscript);

    // let base64String = btoa(forced_aruba_idsdi);

    // console.log(btoa(forced_aruba_idsdi));

    let payload = {
      id: file.name + "_" + this.state.activeUser,
      dataFile: brandonscript64,
      name: file.name,
      credential: "",
      domain: "",
      username: this.state.activeUser
    };
    // upload this payload to array in backend

    console.log(payload);

    try {
      await this.uploadToCloud(payload);
    } catch (error) {
      console.log(error.response);

      error.response.data.shift();

      let tupleBoi = {
        filename: file.name,
        errorMessages: error.response.data
      };

      this.setState({ tuplebois: [...this.state.tuplebois, tupleBoi], loading: false });
    }
  }

  async uploadToCloud(payload) {
    this.setState({ errorMessage: "", loading: true });

    console.log("uploading ---- to cloud ..... ");
    // console.log(payload)

    let response = await axios.post(this.url, payload);

    console.log(response);
    // let refetch = await this.fetchElasticRecords();
  }

  deleteFattura(fattura_clicked) {
    console.log(fattura_clicked);
    //this.state.fileList.filter()

    // if it does not have an id, its not on the database, so simply remove if from the list
    if (fattura_clicked.id === undefined) {
      let filtered = this.state.fileList.filter(
        fattura => fattura.name !== fattura_clicked.name
      );
      this.setState({ fileList: filtered });
    } else {
      // remove it from elasticSearch
      axios.delete(this.url + `?id=${fattura_clicked.id}`).then(response => {
        console.log(response);
        let filtered = this.state.fileList.filter(
          fattura => fattura.name !== fattura_clicked.name
        );
        this.setState({ fileList: filtered });
      });
    }
  }

  async fetchThePreview(url) {

    this.setState({ activeFileName: url })

    console.log(url);

    let generated_url = `${process.env.REACT_APP_SERVER_URL}/api/fattura/da_inviare_preview/${url}_${this.state.activeUser}`
    console.log(generated_url);
    let response = await axios.get(generated_url);
    this.setState({ htmlPreview: response.data, visible: true, loadingAllegati: false })

  }


  renderItems() {
    return this.state.fileList.map(file => (
      <div key={file.name}>
        <button id="deleteFatturaBtn" onClick={() => this.deleteFattura(file)}>
          {" "}
          Rimuovi Fattura{" "}
        </button>{" "}

        <Button type="link" id="nameFatturaLoading" onClick={() => this.fetchThePreview(file.name)} > <span style={{ textDecoration: 'underline' }}> {file.name} </span> </Button>
      </div>
    ));
  }

  error() {
    let errors = this.state.tuplebois.map((tuple, index_tuple) => {
      return (
        <div key={index_tuple}>
          <p> {tuple.filename} </p>

          {tuple.errorMessages.map((message, index_tuple_message) => {
            return <ul key={index_tuple_message}> {message} </ul>;
          })}
        </div>
      );
    });
    //console.log("qweqweqwe " + errors)

    Modal.error({
      title: "Ci sono stati errori durante il caricamento delle fatture",
      content: <div>{errors} </div>
    });
  }


  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async addAllegato(event) {

    this.setState({ loadingAllegati: true })

    console.log('adding allegato to file');

    console.log(event.target.files);
    if (event.target.value.length === 0) {
      console.log("Suspect Cancel was hit, no files selected.");
      return;
    }

    let files = event.target.files;

    console.log(files[0]);

    // if file is bigger than 10 Mb discard it
    if (files[0].size > 10000000) {
      console.log('file too big');
      return
    }

    let filename = files[0].name;

    let base64pdf_data = await this.toBase64(files[0])

    base64pdf_data = base64pdf_data.substring(28)

    // we got the base64 pdf data, time to send it to the backend to handle it  
    //    console.log(base64pdf_data);
    // axios post 

    let payload = {
      id: this.state.activeFileName + "_" + this.state.activeUser,
      nome_attachment: filename,
      attachment_base64: base64pdf_data
    }

    //   console.log(payload);

    let response = await axios.post(this.url + "/add_allegato", payload);

    console.log(response);

    // refresh the page

    this.fetchThePreview(this.state.activeFileName)

  }


  async deleteAllegati() {
    //
    let response = await axios.delete(this.url + "/allegati?id=" + this.state.activeFileName + "_" + this.state.activeUser)
    this.fetchThePreview(this.state.activeFileName)
    console.log(response);
  }


  render() {
    return (
      <div>
        <Modal
          title="Anteprima"
          visible={this.state.visible}
          onOk={() => this.setState({ visible: false })}
          onCancel={() => this.setState({ visible: false })}
          footer={[

            <div key="1" className="allegati-upload">
              <label className="allegati-upload-box">
                <input
                  style={{ display: "none" }}
                  accept=".pdf, .txt, .zip, .rar"
                  type="file"
                  name="file"
                  onChange={e => {
                    this.addAllegato(e);
                    e.target.value = null;
                  }}
                />
                <p className="ant-upload-drag-icon">
                  <Icon type="file-pdf" />
                </p>
                <p className="ant-upload-text">Clicca Per Aggiungere Allegati</p>
              </label>
              <Button loading={this.state.loadingAllegati} className="rimuovi-allegati" key="3" onClick={() => this.deleteAllegati()} > ✖ Rimuovi allegati </Button>
            </div>
            ,

            <Button
              key="2"
              id="popUpCancelBtn"
              onClick={() => this.setState({ visible: false })}
            >
              Ok
              </Button>
          ]}
        >

          <div className="PDFView"
            ref={el => (this.componentRef = el)}
            dangerouslySetInnerHTML={{
              __html: this.state.htmlPreview
            }}
          />


        </Modal>


        <label className="custom-file-upload">
          <input
            style={{ display: "none" }}
            multiple
            accept=".xml"
            type="file"
            name="file"
            onChange={e => {
              this.onUpload(e);
              e.target.value = null;
            }}
          />
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">Clicca Per Aggiungere Fatture</p>
        </label>

        <div id="numeroFattureCaricate">
          Numero totale di fatture caricate :{" "}
          <span id="realNumeroFattureCaricate">
            {this.state.fileList.length}
          </span>
        </div>
        <div id="fattureCaricateContainer">
          {this.state.loading ? (
            <Icon type="loading" style={{ fontSize: 24 }} spin />
          ) : (
              <div id="idFatturaCaricata"> {this.renderItems()} </div>
            )}
        </div>
      </div>
    );
  }
}
