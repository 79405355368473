
import React, { Component } from "react";

import { Tabs, Badge } from "antd";
import "../sass/DateFilter.scss";
import { AuthContext } from "../../context/auth";
const { TabPane } = Tabs;
export default class DateFilter extends Component {
  // probably will need to lift its state up to the dashboard parent, and then the dashbaord will pass the month year day to the FatturaList component

  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.updateYear = this.updateYear.bind(this);
    this.updateMonth = this.updateMonth.bind(this);
  }

  //as this updates refetch the stuff for this specific date
  updateYear(year) {
    console.log("updating year " + year);
    if (this.props.direction === "in") {
      this.props.changeYear(year, "ricevuta");
    } else {
      this.props.changeYear(year, "tutte");
    }
  }

  //as this updates refetch the stuff for this specific date
  updateMonth(month) {

    if (month !== '0') {
      month = month.padStart(2, "0");
    }

    console.log("updating month " + month);
    console.log(this.props.direction);
    if (this.props.direction === "in") {
      this.props.changeMonth(month, "ricevuta");
    } else {
      this.props.changeMonth(month, "tutte");
    }
  }

  render() {
    const monthNames = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre"
    ];

    let years_from_extreme_dates = this.props.years.map(year => {
      // check with index and length for the last document

      return <TabPane tab={year} key={year.toString()} />;
    });

    const fatture_per_month = this.props.fatture_per_month;


    const monthsToRender = fatture_per_month.filter(
      element =>
        new Date(element.key_as_string).getFullYear().toString() === this.props.currentYear
    );

    /*
    // used for debug only
  console.log("========")

  monthsToRender.forEach(element => {
    console.log(element)
    console.log(new Date(element.key_as_string).getFullYear().toString())
    console.log(this.props.currentYear)
  });

  console.log("========")
  */



    const tempMonths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    const not_read = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    for (let i = 0; i < monthsToRender.length; i++) {
      const element = monthsToRender[i];

      tempMonths[new Date(element.key_as_string).getMonth()] =
        element.doc_count;

      not_read[new Date(element.key_as_string).getMonth()] =
        element.not_read_count;
    }

    let monthTabs = [];

    console.log(tempMonths);

    for (let i = 0; i < monthNames.length; i++) {
      monthTabs.push(
        <TabPane
          tab={
            <div>
              {monthNames[i]}
              <Badge
                style={{ marginLeft: "10px" }}
                count={tempMonths[i]}
                overflowCount={10000}
              />
              <Badge
                style={{
                  backgroundColor: "#e27b0b",
                  color: "white",
                  marginLeft: "10px"
                }}
                count={not_read[i]}
                overflowCount={10000}
              />
            </div>
          }
          key={i + 1}
        />
      );
    }

    // select the most recent year of this user

    return (
      <div>
        <Tabs
          className="yearsNav"
          animated={false}
          activeKey={this.props.activeYear}
          onChange={this.updateYear}
          defaultActiveKey={this.props.defaultActiveKey}
        >
          {years_from_extreme_dates}
        </Tabs>

        <Tabs
          className="monthsTabUser"
          animated={false}
          onChange={this.updateMonth}
          defaultActiveKey={(new Date().getMonth() + 1).toString()}
        >
          <TabPane tab={<div>Tutte</div>} key="0" />

          {monthTabs}
        </Tabs>
      </div>
    );
  }
}