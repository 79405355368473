import React, { Component } from "react";
import { NavDropdown, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import "../../sass/ActionButtons.scss";

export default class Preview extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    console.log(this.props);
  }

  render() {
    return (
      <NavDropdown
        title={
          <span>
            <i className="fas fa-file-pdf" />
            Preview{" "}
          </span>
        }
        className="navToBtn previewButtonRightPane"
        id="basic-nav-dropdown"
      >
        <ToggleButtonGroup
          className="previewButtons"
          type="radio"
          name="options"
          defaultValue={1}
        >
          <ToggleButton
            variant="info"
            className="sendSentBtn"
            value={1}
            onClick={() => this.props.updatestatePreviewDFBtn(true)}
          >
            Default
          </ToggleButton>
          <ToggleButton
            variant="info"
            className="sendSentBtn"
            value={2}
            onClick={() => this.props.updatestatePreviewDFBtn(false)}
          >
            Ministeriale
          </ToggleButton>
        </ToggleButtonGroup>
      </NavDropdown>
    );
  }
}
