import axios from "axios";
import React, {Component} from "react";
import {NavDropdown, Spinner} from "react-bootstrap";
import "../../sass/ActionButtons.scss";

export default class Allegati extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allegati: [],
            loading_allegati: false
        };

        this.fetchAllegati.bind(this);
    }

    async fetchAllegati() {

        this.setState({loading_allegati: true});

        let filename = this.props.filename;

        await axios
            .get(
                `${process.env.REACT_APP_SERVER_URL}/api/fattura/allegati/${filename}`
            )
            .then(response => {
                console.log(response.data);
                this.setState({allegati: response.data, loading_allegati: false});
            });
    }

    downloadAllegato() {
        let a = document.createElement("a");
        a.href =
            "data:application/octet-stream;base64," +
            this.state.allegato.Allegati.Attachment;
        a.download =
            this.state.allegato.Allegati.NomeAttachment +
            "." +
            this.state.allegato.Allegati.FormatoAttachment;
        a.click();
    }

    renderAllegati() {
        if (this.state.allegati.length > 0) {
            const allegatiList = this.state.allegati.map(allegato => {
                return (
                    <NavDropdown.Item
                        onClick={() =>
                            this.setState({allegato: allegato}, () =>
                                this.downloadAllegato()
                            )
                        }
                        key={allegato.Allegati.NomeAttachment}
                        className="notificheButtons"
                    >
                        {allegato.Allegati.NomeAttachment}
                    </NavDropdown.Item>
                );
            });
            return allegatiList;
        } else {
            return (
                <p className="msgAllegati"> Non ci sono Allegati per questa fattura </p>
            );
        }
    }

    render() {
        return (
            <div>
                <NavDropdown
                    onSelect={this.showModal}
                    onClick={() => this.fetchAllegati()}
                    title={
                        <span>
              {/* <img className="btnIconsRP" src="https://image.flaticon.com/icons/svg/136/136522.svg" style={{ height: "18px" }} /> */}
                            <i className="fas fa-paperclip"/> Allegati{" "}
            </span>
                    }
                    className="navToBtn allegatiButtonRightPane"
                    id="basic-nav-dropdown"
                >
                    {this.state.loading_allegati
                        ? true && (
                        <Spinner
                            id="spinnerLoading"
                            animation="border"
                            variant="primary"
                        />
                    )
                        : this.renderAllegati()}
                </NavDropdown>
            </div>
        );
    }
}
