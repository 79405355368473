import React, { Component } from "react";
import "../sass/Settings.scss";


export default class DesktopClient extends Component {
  render() {
    return (
      <div>

        <a href="https://github.com/nizarhmain/etin-electron/releases/download/v0.2.0/Etin-Cloud-Setup-0.2.0.exe">

            <div className="downloadSection">

        <img alt="windows_logo" height="30px" width="30px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Windows_logo_-_2012_derivative.svg/1024px-Windows_logo_-_2012_derivative.svg.png"/>
        <span id="scarica_text">          Scarica   </span>
<img alt="beta_indicator" src="https://img.shields.io/badge/versione-beta0.2-yellow" /> 
        </div>
        </a>
      </div>
    );
  }
}
