import { Button, Modal } from "antd";
import React, { Component } from "react";
import SettingsTabs from "./SettingsTabs";


import "../sass/Settings.scss";

import { AuthContext } from "../../context/auth";
export default class Settings extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      error: ""
    };
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = e => {

    // save the stuff here
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  onChange(value) {
    console.log(`selected ${value}`);
    this.setState({ direction: value });
  }

  renderingSettingsBtn() {
    if (this.context.username === this.context.immutable_username) {
      return (
        <div>
          <Modal
            title="Impostazioni"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button
                id="popUpCancelBtn"
                key="back"
                onClick={this.handleCancel}
              >
                Ok
              </Button>
            ]}
          >
            <SettingsTabs />
          </Modal>

          <Button
            onClick={this.showModal}
            className="navBtn"
            loading={this.state.loading}
            id="impostazioniMainBtnX"
          >
            <i className="fas fa-cog" />
            Impostazioni
          </Button>
        </div>
      );
    }
  }

  render() {
    return <div>{this.renderingSettingsBtn()}</div>;
  }
}
