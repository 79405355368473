import axios from 'axios'


// the username is taken from the context
// the direction is taken from the Fattura Manager calling the function


export default async function getCountPerMonth(direction) {
        let final_output = await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/fattura/fatture_per_month`)
        .then(response => {

            //console.log(response.data.count_not_read);

            let not_read = response.data.count_not_read.aggregations.fatture_per_month.avg.buckets;

            // merge the two arrays
            let firstQuery = response.data.count_per_month.firstQuery.aggregations.fatture_per_month.avg.buckets
            let secondQuery = response.data.count_per_month.secondQuery.aggregations.fatture_per_month.avg.buckets


            let aggregationsOfTwoQueries = firstQuery.concat(secondQuery);

            let result = []

            aggregationsOfTwoQueries.forEach(function (a) {
                if (!this[a.key_as_string]) {
                    this[a.key_as_string] = { key_as_string: a.key_as_string, doc_count: 0 };
                    result.push(this[a.key_as_string]);
                }
                this[a.key_as_string].doc_count += a.doc_count;
            }, Object.create(null));

            console.log(result);

            let aggregation_of_result_and_new = result.concat(not_read);

            let final_result = [];

            aggregation_of_result_and_new.forEach( function (month_record) {

                if (!this[month_record.key_as_string]) {
                    this[month_record.key_as_string] = { key_as_string: month_record.key_as_string, doc_count: month_record.doc_count };
                    final_result.push(this[month_record.key_as_string]);
                } else {
                    // let initial_doc_count = this[month_record.key_as_string].doc_count
                    let not_read_count = month_record.doc_count

                    this[month_record.key_as_string].not_read_count = not_read_count;
                }
            }, Object.create(null));

            if (direction === 'out') {
                return result
            } else {
                return final_result
            }

        })


        return final_output;
}