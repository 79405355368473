import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Form, Input } from "antd";
import { withRouter } from "react-router";
import axios from "axios";
import "./sass/Login.scss";

import { AuthContext } from "../context/auth";

class Login extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      result_status: ""
    };
  }

  componentDidMount() {
    if (localStorage.getItem("immutable_token")) {
      this.props.history.push(`/dashboard`);
    }
  }

  login = event => {
    event.preventDefault();

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/user/auth/signin`, {
        username: this.state.username,
        password: this.state.password
      })
      .then(response => {
        console.log(response);
        this.setState({ result_status: "success" });

        // this is the user object from the database
        let user = response.data;
        this.context.logIn(user);

        //redirect to dashboard
        this.props.history.push(`/dashboard`);
      })
      .catch(error => {
        this.setState({ result_status: "error" });
      });
  };

  render() {
    let result_h2_message;

    if (this.state.result_status === "error") {
      result_h2_message = (
        <p id="errorMessageLogin">Username o Password Errati!</p>
      );
    } else {
      result_h2_message = <div/>;
    }

    return (
      <div className="containerLogin">
        <div id="leftDivLogin">
          <div id="leftDivLoginContainer">
            <h1 id="titleLogin">eTIN-Web</h1>
            <Form>
              <Form.Item
                hasFeedback
                validateStatus={this.state.result_status}
                controlId="formBasicEmail"
              >
                <Input
                  onChange={e => this.setState({ username: e.target.value })}
                  placeholder="Username"
                  id="warning"
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                validateStatus={this.state.result_status}
                controlId="formBasicPassword"
              >
                <Input
                  onChange={e => this.setState({ password: e.target.value })}
                  placeholder="Password"
                  id="warning"
                  type="password"
                />
              </Form.Item>

              {result_h2_message}

              <Button
                onClick={this.login.bind(this)}
                variant="primary"
                type="submit"
              >
                Sign In
              </Button>
            </Form>
          </div>
        </div>
        <div id="rightDivLogin"></div>
      </div>
    );
  }
}

export default Login = withRouter(Login);
