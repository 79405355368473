import axios from "axios";
import React, { Component } from "react";
import { AuthContext } from "../../../context/auth";

import { Select } from "antd";
const { Option } = Select;

export default class ListaUtenti extends Component {
  static contextType = AuthContext;
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      listaUtenti: [],
    };
    this.updateSelectedUtente = this.updateSelectedUtente.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.fetchFornitoresClients();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateSelectedUtente(utente) {
    console.log(
      "logging in with the user" + utente.username + "  " + utente.etinPassword
    );
    this.context.changeActiveToken(utente.username, utente.etinPassword);
  }

  compare(a, b) {
    if (a.azienda < b.azienda) {
      return -1;
    }
    if (a.azienda > b.azienda) {
      return 1;
    }
    return 0;
  }

  async fetchFornitoresClients() {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/user/me`)
      .then((response) => {
        if (this._isMounted) {
          this.setState(
            { listaUtenti: response.data.clients.sort(this.compare) },
            () => console.log(this.state)
          );
        } else {
          window.location.reload();
        }
      });
  }

  render() {
    /* 
    const listLeftUpper = this.state.listaUtenti.map(utente => (

      <Option key={utente.username} href="#/action-1" onClick={() => this.updateSelectedUtente(utente)} key={utente.username}>
        {utente.azienda}
      </Option>

    ));

 */
    const listLeftUpper = this.state.listaUtenti.map((utente) => (
      <Option
        key={utente.username}
        value={utente.azienda}
        onClick={() =>
          this.props.updateSelectedUtente(utente.username, utente.etinPassword)
        }
      >
        {utente.azienda}
      </Option>
    ));

    return (
      <div>
        <Select style={{ width: "300px" }} className="" showSearch={true}>
          <Option key={"admin"} onClick={() => this.props.backToFornitore()}>
            {this.context.immutable_azienda}{" "}
            <i className="fa fa-star" aria-hidden="true"></i>
          </Option>

          {listLeftUpper}
        </Select>
      </div>
    );
  }
}
