import React, { Component } from 'react'
import { Select } from "antd";
const { Option } = Select;


export default class FilterByCliente extends Component {
    constructor(props) {
        super(props)

        this.state = {
            focused: false
        }

        this.onSearch = this.onSearch.bind(this)
    }


    componentDidUpdate(prevProps, prevState) {
        console.log(prevProps);
        console.log(prevState);
    }



    renderOption(cliente, index) {
        return (
            // onClick={() => this.setState({ selectedFattura: fattura._source.id })}
            <Option key={index} value={cliente}>{cliente}</Option>
        )
    }

    ClientiFilter() {

        console.log(' figure out the clienti of this page');

        this.props.clienti.sort();

        const options = this.props.clienti.map((cliente, index) => (
            this.renderOption(cliente, index)
        ));

        return (

            <div className="masterP" >
                <p className=""> Cerca Cliente : </p>
                <Select style={{ width: '300px' }}
                    className=""
                    showSearch={true}
                    value={this.props.clienteFilter}
                    placeholder="Select a status you want to filter"
                    optionFilterProp="children"
                    onChange={this.props.filterCliente}
                >
                    <Option value="tutti">Tutti</Option>
                    {options}
                </Select>

            </div>)
    }


    onSearch(val) {
        console.log('search:', val);
    }


    FornitoriFilter() {

        console.log(' figure out the fornitori of this page');

        this.props.fornitori.sort();

        const options = this.props.fornitori.map((fornitore, index) => (
            this.renderOption(fornitore, index)
        ));

        return (

            <div className="masterP" >
                <p className="">Cerca Fornitore : </p>
                <Select style={{ width: '300px' }}
                    className=""
                    showSearch={true}
                    onFocus={this.onFocus}
                    value={this.props.fornitoreFilter}
                    placeholder="Select a status you want to filter"
                    optionFilterProp="children"
                    onChange={this.props.filterFornitore}
                >
                    <Option value="tutti"> Tutti </Option>
                    {options}
                </Select>

            </div>)
    }

    render() {

        return (
            <div>
                {this.props.direction === "in" ? this.FornitoriFilter() : this.ClientiFilter()}
            </div>
        )
    }

}
