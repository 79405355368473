import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Login from "./Login";
import Dashboard from "./Dashboards/Dashboard";
import { AuthContext } from "../context/auth";
import NotFound from "./NotFound";

import '../axios-interceptor';

// comment this when debugging
console.log = function() {}

export default class App extends Component {

  static contextType = AuthContext;


  render() {
    return (
      <Router>
        <Route path="/" exact component={Login} />
        <Route path="/dashboard" component={Dashboard} />
        <Route component={NotFound} />
      </Router>
    );
  }
}
