import TextField from "@material-ui/core/TextField";
import axios from "axios";
import React, { Component } from "react";
import { Modal } from "antd";
import {
  Button,
  DropdownButton,
  Form,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import "../sass/MainNavBar.scss";
import DownloadMultiplePDFS from "./DownloadMultiplePDFS";
import ListaUtenti from "./FornitoreButton/ListaUtenti";
import Invio from "./Invio/Invio";
import Report from "./Report";
import Settings from "../Settings/Settings";

class MainNavBar extends Component {
  static contextType = AuthContext;


  constructor(props) {
    super(props);

    this.state = {
      visibile: false,
      partitaIva: "",
      credit: 0,
      attivo: false,
    };
    this.logout = this.logout.bind(this);
    this.fetchCredit = this.fetchCredit.bind(this);
  }

  componentDidMount() {

    this.fetchCredit();

    console.log(this.context);

      this.setState({
        activeUser: this.context.immutable_username,
        azienda: this.context.azienda,
        attivo: this.context.attivo,
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.context.role === "admin") {
      console.log(this.state.activeUser);
      console.log(this.context.username);

      if (this.context.username !== this.state.activeUser) {
        this.setState({ activeUser: this.context.username });
        this.fetchCredit();
        console.log("something got updated");
      }
    }
  }

  async fetchCredit() {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/user/me`)
      .then((response) => {
        console.log(response);
        this.setState({
          partitaIva: response.data.partitaIva,
          credit: response.data.credit,
          attivo: response.data.attivo,
          visible: !response.data.attivo,
        });
      });
  }

  logout() {
    this.context.logOut();

    // localStorage.clear();
    // this.props.history.push("/");
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    let tooltip1 = (
      <Tooltip id="tooltip-top">
        <span>
          <b>IVA: </b>
        </span>
        <span>{this.state.partitaIva} </span>
        <br />
        <span>
          <b>Rimaste: </b>
        </span>
        <span>{this.state.credit}</span>
        <br />
      </Tooltip>
    );

    return (
      <div>
        <Modal
          title="Account non attivo !"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <h4>
            {" "}
            Account non attivo{" "}
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          </h4>
          <h4> Conttatare l'assistenza </h4>
        </Modal>

        <Navbar id="mainNavBar" className="shadow" bg="light" expand="lg">
          <OverlayTrigger placement="bottom" overlay={tooltip1}>
            <Navbar.Brand className="brand">
              <i className="fas fa-check-circle checkBranStatus" />
              {this.context.azienda}
            </Navbar.Brand>
          </OverlayTrigger>

          <Nav className="mr-auto afterBrandBtns">
            {/* {admin_username == this.props.selectedUser && <Invio credit={this.state.credit} />} */}
            <Invio credit={this.state.credit} attivo={this.state.attivo} />
            <Report />
            <DownloadMultiplePDFS />
          </Nav>

          <Form inline className="justify-content-end">
            <TextField
              onChange={this.props.searchOnChange}
              type="text"
              placeholder="Cerca..."
              className="mr-sm-2"
            />

          </Form>
          <Settings />

          <Button
            className="navBtn"
            id="esciMainBtn"
            onClick={() => this.logout()}
          >
            <i className="fas fa-sign-out-alt" />
            Esci
          </Button>

          {this.context.role === "fornitore" && (
            <DropdownButton alignRight id="clientiDropBtn" title="Clienti">
              <ListaUtenti
                id="listUtentiContainer"
                updateSelectedUtente={this.props.updateSelectedUtente}
                backToFornitore={this.props.backToFornitore}
              />
            </DropdownButton>
          )}

          {this.context.role === "admin" && (
            <DropdownButton alignRight id="clientiDropBtn" title="Clienti">
              <ListaUtenti
                id="listUtentiContainer"
                updateSelectedUtente={this.props.updateSelectedUtente}
                backToFornitore={this.props.backToFornitore}
              />
            </DropdownButton>
          )}
        </Navbar>
      </div>
    );
  }
}

export default MainNavBar = withRouter(MainNavBar);
