import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./index.scss";
import { LocaleProvider } from 'antd';
import it_IT from 'antd/es/locale-provider/it_IT';
import moment from 'moment';
import 'moment/locale/it';


import { Provider }  from "./context/auth";


moment.locale('it');


ReactDOM.render(
    
    
    <LocaleProvider locale={it_IT}>
        
        <Provider>
            <App />
        </Provider>
        
        </LocaleProvider>
    , document.querySelector("#root"));
