import axios from "axios";
import React, { Component } from "react";
import "../sass/FatturaCardMulti.scss"
import "../sass/FatturaCard.scss";
import { Collapse } from 'antd';


const { Panel } = Collapse;



export default class FatturaCardMulti extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pointerEvents: "all"
    };

    this.openHistory = this.openHistory.bind(this)

  }


  openHistory(e) {
    console.log("history opened");
  }

  async fetchHTMLpreview(fattura) {
    let new_url = `${process.env.REACT_APP_SERVER_URL}/api/fattura/one_pdf?fattura_id=${fattura._source.id}`;
    this.props.setLoadingRightPane(true);
    await axios
      .get(new_url)
      .then(response => {
        // console.log(response.data);
        this.props.setHtmlPreview(response.data, false);
      })
      .catch(error => {
        console.log(error);
        this.props.setHtmlPreview("error", false);
      });
  }

  async fetchHTMLministeriale(fattura) {
    this.props.setLoadingRightPane(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/fattura/ministeriale?id=${fattura._source.id}`
      )
      .then(response => {
        this.props.setHTMLMinisteriale(response.data, false);
      })
      .catch(error => {
        console.log(error);
        this.props.setHtmlPreview("error", false);
      });
  }


  renderStatusIcon(status) {
    switch (status) {
      case "Ricevuta":
        return <i className="fas fa-check cardIconsRightX" />;
      case "Consegnata":
        return <i className="fas fa-check cardIconsRightX" />;
      case "Non consegnata":
        return <i className="fas fa-exclamation cardIconsRightX" />;
      case "Inviata":
        return <i id="icon_inviata" className="fas fa-check cardIconsRightX" />;
      case "Scartata":
        return <i className="fas fa-trash cardIconsRightX" />;
      case "Decorrenza termini":
        return <i className="fas fa-exclamation cardIconsRightX" />;
      case "Accettata":
        return <i className="fas fa-thumbs-up cardIconsRightX" />;
      case "Rifiutata":
        return <i className="fas fa-thumbs-down cardIconsRightX" />;
      case "Errore elaborazione":
        return <i className="fas fa-times cardIconsRightX" />;
      case "Recapito Impossibile":
        return <i className="fas fa-times cardIconsRightX" />;
      default:
        return <i className="fas fa-exclamation cardIconsRightX" />;
    }
  }



  renderStatus() {
    let statusString = this.props.fattura._source.invoices[
      this.props.fattura._source.invoices.length - 1
    ].status;
    return (
      <div>
        {this.renderStatusIcon(statusString)}
        <b>{statusString}</b>
      </div>
    );
  }

  renderDataDocumento() {
    return this.props.fattura._source.invoices[0].invoiceDate.substring(0, 10);
  }

  // takes a fattura or sub fattura
  onFatturaCardClick(fattura) {

    let id = fattura._source.id;

    this.setState({ loadingRightPane: true });

    this.props.updateSelectedXML(fattura);

    console.log(fattura);

    this.fetchHTMLpreview(fattura);

    this.fetchHTMLministeriale(fattura);

    if (
      fattura._source.docType === "in" &&
      fattura._source.read === undefined
    ) {
      this.markAsRead(fattura._source, id);
    }
  }

  markAsRead(fattura, id) {
    // disable card
    //this.setState({ pointerEvents: 'none' })

    let url = `${process.env.REACT_APP_SERVER_URL}/api/fattura/mark_as_read?fattura_id=${id}`;

    // mark as read in the server
    axios({
      method: "get",
      url: url
    })
      .then(response => {
        fattura.read = true;
        let currentMonth = this.props.currentMonth;
        let currentYear = this.props.currentYear;

        this.props.fatture_per_month.forEach((month_record, index) => {
          let date = new Date(month_record.key_as_string);

          let month = (date.getMonth() + 1).toString();
          let padded_month = month.padStart(2, "0");
          let year = date.getFullYear().toString();

          if (padded_month === currentMonth && year === currentYear) {
            this.props.updateFatturePerMonth(index);
          }
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  isoDateToShortDate(iso_date) { }

  renderLetta() {
    return (
      <div id="lettaIcon">
        <i className="fas fa-eye cardIconsLeft" /> Letta
      </div>
    );
  }

  renderNonLetta() {
    return (
      <div id="nonLettaIcon">
        <i className="fas fa-eye-slash cardIconsLeft" /> Non Letta{" "}
      </div>
    );
  }

  renderStampata() {
    return (
      <div id="lettaEStampataIcon">
        <i className="fas fa-check-double cardIconsLeft" /> Letta e Stampata
      </div>
    );
  }

  conditionalRendering() {
  if (this.props.fattura._source.docType === "in") {
      let read = this.props.fattura._source.read;
      let printed = this.props.fattura._source.printed;

      if (read === undefined) {
        return this.renderNonLetta();
      }

      if (read !== undefined && printed !== undefined) {
        return this.renderStampata();
      }

      if (read !== undefined) {
        return this.renderLetta();
      }
    }
  }


  renderSubCard(sub_fattura) {

    return (
      <div key={sub_fattura._id} className="sub_fattura" onClick={() => this.onFatturaCardClick(sub_fattura)}>

        <div id="">
          <i className="fas fa-sync-alt cardIcons cardIconsRight" />
          {sub_fattura._source.creationDate.substring(0, 19)}
        </div>

        <b>{sub_fattura.fattura_status}</b>
        {this.renderStatusIcon(sub_fattura.fattura_status)}


      </div>
    )
  }


  renderSenderOrReceiver() {

    if (this.props.fattura._source.docType === "in") {
      return (
        <div>
          {this.props.fattura._source.sender.description}
          <i className="fas fa-building cardIconsLeft" />
        </div>
      )
    } else {
      return (<div>
        {this.props.fattura._source.receiver.description}
        <i className="fas fa-file-import cardIcons cardIconsRight" />
      </div>)
    }
  }


  renderSubList(fattura) {
    return fattura.sub_fatture.map((sub_fattura) => {
      return this.renderSubCard(sub_fattura)
    })

  }

  render() {
    return (
      <div style={{ pointerEvents: this.state.pointerEvents }}>
        <div className={
          this.props.selectedFattura === this.props.selectedFatturaId
            ? "containerFatturaCard containerFatturaCardACTIVE"
            : "containerFatturaCard"
        }>
          <div
            onClick={() => {
              this.onFatturaCardClick(this.props.fattura);
            }}
            key={this.props.fattura._source.id}
          >

            <div id="firstRowNG">

              <div id="numeroFattura">
                <b>
                  <i className="fas fa-hashtag cardIconsLeft" />{" "}
                  {this.props.fattura._source.invoices[0].number}
                </b>
              </div>

              <div id="creationDateFattura">
                {this.renderDataDocumento()}
                <i className="fas fa-edit cardIconsLeft" />
              </div>

            </div>
            <div id="secondRowNG">
              <div id="senderFattura">
                {this.props.fattura._source.filename}
              </div>
              <div id="receiverFattura">
                {this.renderSenderOrReceiver()}
              </div>
            </div>
            <div id="fourthRowNG">
              <div style={{ width: '130px' }}>
                {this.conditionalRendering()}
              </div>

              <div id="statoFattura">{this.renderStatus()}</div>

              <div id="lastUpdateDateDateFattura">
                {this.props.fattura._source.creationDate.substring(0, 10)}
                <i className="fas fa-sync-alt cardIcons cardIconsRight" />
              </div>{" "}
            </div>
          </div>

          <Collapse id="sub_fattura_collapse" bordered={false} expandIconPosition="right" onClick={() => this.openHistory()}>
            <Panel header="Elenco invii precedenti" key="1">
              {this.renderSubList(this.props.fattura)}
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}
