import TextField from "@material-ui/core/TextField";
import { Button, Modal, Select } from "antd";
import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import TimePicker from "./TimePicker";


const { Option } = Select;

export default class DownloadMultiplePDFS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      direction: "in",
      startMonth: "",
      endMonth: "",
      loading: false,
      currentDateType: "invoices.invoiceDate",
      error: "",
      filternumber: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onText = this.onText.bind(this);
    this.timeSelected = this.timeSelected.bind(this);
  }

  componentDidMount() {
    var d = new Date();
    d.setMonth(d.getMonth() - 1);

    this.setState({
      startMonth: moment(d).format("YYYY-MM-DD"),
      endMonth: moment(new Date()).format("YYYY-MM-DD")
    });
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    console.log(e);

    this.setState({ loading: true, error: "" });
    let url2 = this.state.filternumber ? `&filter=${this.state.filternumber}` : '';
    let url = `${process.env.REACT_APP_SERVER_URL}/api/fattura/multi_default?direction=${this.state.direction}&startMonth=${this.state.startMonth}&endMonth=${this.state.endMonth}&dateType=${this.state.currentDateType}${url2}`;

    axios({
      method: "get",
      url: url,
      responseType: "arraybuffer"
    })
      .then(response => {
        this.setState({ loading: false });

        let blob = new Blob([response.data], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Fatture ${this.state.direction} pdf dal ${this.state.startMonth} al ${this.state.endMonth}`;
        link.click();
      })
      .catch(error => {
        if (error.response.status === 413) {
          this.setState({
            loading: false,
            error:
              "È possibile esportare i dati relativi ad un periodo massimo di 3 mesi"
          });
        } else {
          this.setState({
            loading: false,
            error: "non ci sono fatture in questa data"
          });
        }
      });
    // this.setState({
    //     visible: false,
    // });
  };

  onCurrentDateTypeChange(value) {
    this.setState({ currentDateType: value });
  }

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  onChange(value) {
    console.log(`selected ${value}`);
    this.setState({ direction: value });
  }

  onText(event) {
    let value = event.target.value;
    this.setState({ filternumber: value });
  }

  onBlur() {
    console.log("blur");
  }

  onFocus() {
    console.log("focus");
  }

  onSearch(val) {
    console.log("search:", val);
  }

  timeSelected(dateString) {
    this.setState({ startMonth: dateString[0], endMonth: dateString[1] });
  }
  render() {
    return (
      <div>
        <Modal
          title="Scarica PDF"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button id="popUpCancelBtn" key="back" onClick={this.handleCancel}>
              Cancella
            </Button>,
            <Button
              id="popUpScaricaBtn"
              key="submit"
              type="primary"
              loading={this.state.loading}
              onClick={() => this.handleOk()}
            >
              Scarica PDF
            </Button>
          ]}
        >
          <div id="pdfFattureModalDiv">
            <span id="spanFiltraPDF">Filtra fatture per: </span>
            <TextField
              type="text"
              id="txtFilterNumber"
              onChange={this.onText}
              placeholder="Cerca nel numero..."
            />
            <Select
              id="pdfFattureSelectRI"
              defaultValue="in"
              showSearch
              placeholder="Select a person"
              optionFilterProp="children"
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="in">Ricevute</Option>
              <Option value="out">Inviate</Option>
            </Select>

            <Select
              id="pdfFattureSelectDate"
              showSearch
              placeholder="..."
              defaultValue={this.state.currentDateType}
              optionFilterProp="children"
              onChange={this.onCurrentDateTypeChange.bind(this)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option id="dataRicevute" value="creationDate">
                data ricevute/inviate
              </Option>
              <Option id="dataCreazione" value="invoices.invoiceDate">
                data documento
              </Option>
            </Select>

            <TimePicker timeSelected={this.timeSelected} />
          </div>

          <div>
            <p style={{ color: "red" }}> {this.state.error} </p>
          </div>
        </Modal>

        <Button
          onClick={this.showModal}
          className="navBtn"
          loading={this.state.loading}
          variant="outline-danger"
          id="stampaFattureBtn"
        >
          <i className="fas fa-file-pdf" />
          PDF Fatture
        </Button>
      </div>
    );
  }
}
